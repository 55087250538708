import React, {Component} from 'react';
import {Card} from 'material-ui/Card';
import {ViewTitle} from 'admin-on-rest/lib/mui';
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn,
} from 'material-ui/Table';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import DownloadIcon from 'material-ui/svg-icons/file/file-download';
import FlatButton from 'material-ui/FlatButton';
import {
    downloadCreativeCertificatesOfMonth,
    getCreativeCertificatesOfMonths,
} from '../../rest/fetch'


const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const yearOptions = [];
    for (let year = 2022; year <= currentYear; year++) {
        yearOptions.push({value: year, label: year});
    }
    return yearOptions;
}


const monthOptions = [
    {value: 1, label: 'Január'},
    {value: 2, label: 'Február'},
    {value: 3, label: 'Március'},
    {value: 4, label: 'Április'},
    {value: 5, label: 'Május'},
    {value: 6, label: 'Június'},
    {value: 7, label: 'Július'},
    {value: 8, label: 'Augusztus'},
    {value: 9, label: 'Szeptember'},
    {value: 10, label: 'Október'},
    {value: 11, label: 'November'},
    {value: 12, label: 'December'},
];

const selectCard = {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '10px',
}

class DownloadCreativeCertificates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            month: "",
            year: "",
            tableData: [],
        };
    }

    handleYearChange = (event, index, value) => {
        this.setState({year: value, tableData: []});
    };
    handleMonthChange = (event, index, value) => {
        this.setState({month: value, tableData: []});
    };
    handleClick = async () => {
        try {
            const certificates = await getCreativeCertificatesOfMonths({year: this.state.year, month: this.state.month});
            this.setState({tableData: certificates})
        } catch (error) {
            console.error(error);
        }
    }

    handleDownloadCertificatesOfTheMonth = async () => {
        try {
            await downloadCreativeCertificatesOfMonth({year: this.state.year, month: this.state.month});
        } catch (error) {
            console.error(error);
        }
    }

    render() {
        const {year, month, tableData} = this.state
        const yearOptions = generateYearOptions()
        return (
            <div>
                <Card style={{marginBottom: '1em'}}>
                    <ViewTitle title="Teljesítési igazolások letöltése"/>
                </Card>
                <Card style={{paddingBottom: '5px'}}>

                    <Card style={selectCard}>
                        <SelectField
                            value={year}
                            onChange={this.handleYearChange}
                            floatingLabelText="Év"
                        >
                            {
                                yearOptions.map(
                                    option => <MenuItem
                                        key={option.value}
                                        value={option.value}
                                        primaryText={option.label}
                                    />
                                )
                            }
                        </SelectField>

                        <SelectField
                            value={month}
                            onChange={this.handleMonthChange}
                            floatingLabelText="Hónap"
                        >
                            {
                                monthOptions.map(
                                    option => <MenuItem
                                        key={option.value}
                                        value={option.value}
                                        primaryText={option.label}
                                    />
                                )
                            }
                        </SelectField>

                        <FlatButton
                            onClick={this.handleClick}
                            primary
                            label={'Listázás'}
                        />

                        <FlatButton
                            style={{position: 'absolute', right: 50, marginLeft: '200px'}}
                            onClick={this.handleDownloadCertificatesOfTheMonth}
                            primary
                            label={'Összes TIG letöltése'}
                            disabled={tableData.length === 0}
                        />
                    </Card>

                    <Table>
                        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                            <TableRow>
                                <TableHeaderColumn>Kreatív</TableHeaderColumn>
                                <TableHeaderColumn>Dátum</TableHeaderColumn>
                                <TableHeaderColumn>TIG (HUF)</TableHeaderColumn>
                                <TableHeaderColumn>TIG (EUR)</TableHeaderColumn>
                            </TableRow>
                        </TableHeader>
                        <TableBody displayRowCheckbox={false}>
                            {
                                tableData
                                && tableData.map(
                                    (row) => <TableRow key={row.creative}>
                                        <TableRowColumn component="th" scope="row">
                                            {row.creative}
                                        </TableRowColumn>
                                        <TableRowColumn>{row.date}</TableRowColumn>
                                        <TableRowColumn>
                                            {
                                                !!row.certificatesByCurrencies.HUF
                                                && <a href={row.certificatesByCurrencies.HUF.url}>
                                                    <DownloadIcon/>
                                                </a>
                                            }
                                        </TableRowColumn>
                                        <TableRowColumn>
                                            {
                                                !!row.certificatesByCurrencies.EUR
                                                && <a href={row.certificatesByCurrencies.EUR.url}>
                                                    <DownloadIcon/>
                                                </a>
                                            }
                                        </TableRowColumn>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                    {
                        tableData.length === 0
                        && <p style={{textAlign: 'center'}}>
                            Nincs TIG-gel rendelkező kreatív az adott hónapban.
                        </p>
                    }
                </Card>
            </div>
        );
    }
}

export default DownloadCreativeCertificates;
