import { showNotification, refreshView } from 'admin-on-rest';
import { put, all, takeEvery } from "redux-saga/effects";
import { extractViolations } from './errorSagas';

function* creativeAcceptSuccess() {
  yield put(refreshView());
  yield put(showNotification('Kreatív elfogadva'));
}

function* creativeAcceptFailure({ payload }) {
  const errors = extractViolations(payload)
  let errorMessage = 'Hiba történt elfogadás közben';
  if ('_' in errors && errors['_'].length > 0) {
    errorMessage = `${errorMessage}: ${errors['_'][0]}`
  }
  yield put(refreshView());
  yield put(showNotification(errorMessage, 'warning'));
}

function* creativeRejectSuccess() {
  yield put(refreshView());
  yield put(showNotification('Kreatív elutasítva'));
}

function* creativeRejectFailure({ payload }) {
  const errors = extractViolations(payload)
  let errorMessage = 'Hiba történt elutasítás közben';
  if ('_' in errors && errors['_'].length > 0) {
    errorMessage = `${errorMessage}: ${errors['_'][0]}`
  }
  yield put(refreshView());
  yield put(showNotification(errorMessage, 'warning'));
}

export default function* jurySagas() {
  yield all([
    takeEvery('PROVIDER_ACCEPT_SUCCESS', creativeAcceptSuccess),
    takeEvery('PROVIDER_ACCEPT_FAILURE', creativeAcceptFailure),
    takeEvery('PROVIDER_REJECT_SUCCESS', creativeRejectSuccess),
    takeEvery('PROVIDER_REJECT_FAILURE', creativeRejectFailure),
  ]);
}
