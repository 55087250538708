import React from 'react';

import PropTypes from 'prop-types';
import shouldUpdate from 'recompose/shouldUpdate';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import FlatButton from 'material-ui/FlatButton';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import {deleteExtrasPlusProject} from "../actions/plusProjectActions";


const handle = (e, projectId, deleteExtras) => {
  e && e.stopPropagation();
  e && e.preventDefault();

  deleteExtras(projectId);
}

const PlusProjectDeleteExtrasButton = (
  {
    label = 'Extrák törlése',
    record = {},
    deleteExtrasPlusProject,
  }
) => (
  <FlatButton
    onClick={(e) => handle(e, record.id, deleteExtrasPlusProject)}
    primary
    label={label}
    icon={<DeleteIcon/>}
    style={{overflow: 'inherit', color: 'red'}}
  />
);

PlusProjectDeleteExtrasButton.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  deleteExtrasPlusProject: PropTypes.func,
};

const enhance = compose(
  shouldUpdate(
    (props, nextProps) =>
      (props.record &&
        nextProps.record &&
        props.record.id !== nextProps.record.id) ||
      props.basePath !== nextProps.basePath ||
      (props.record == null && nextProps.record != null)
  ),
  connect(null, {deleteExtrasPlusProject})
);

export default enhance(PlusProjectDeleteExtrasButton);
