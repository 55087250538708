export const VERIFY_PLUS_BRAND_INTEREST = 'VERIFY_PLUS_BRAND_INTEREST';
export const ACCEPT_PLUS_BRAND_OWNER_CHANGE = 'ACCEPT_PLUS_BRAND_OWNER_CHANGE';
export const REJECT_PLUS_BRAND_OWNER_CHANGE = 'REJECT_PLUS_BRAND_OWNER_CHANGE';


export const verifyInterest = (id) => ({
    type: VERIFY_PLUS_BRAND_INTEREST,
    payload: {id},
    meta: { resource: 'enterprise-clients', fetch: VERIFY_PLUS_BRAND_INTEREST, cancelPrevious: false },
});

export const acceptOwnerChange = (id) => ({
    type: ACCEPT_PLUS_BRAND_OWNER_CHANGE,
    payload: {id},
    meta: { resource: 'enterprise-client-owner-modify-requests', fetch: ACCEPT_PLUS_BRAND_OWNER_CHANGE, cancelPrevious: false },
});

export const rejectOwnerChange = (id) => ({
    type: REJECT_PLUS_BRAND_OWNER_CHANGE,
    payload: {id},
    meta: { resource: 'enterprise-client-owner-modify-requests', fetch: REJECT_PLUS_BRAND_OWNER_CHANGE, cancelPrevious: false },
});
