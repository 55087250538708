import {plainHttpClient} from "./fetch";
import config from "../config";


function uploadIfNewPicture(refPicture, packageId) {
    if (refPicture.rawFile instanceof File) {
        const formData = new FormData()
        formData.append('file', refPicture.rawFile)

        return plainHttpClient(
            `${config.apiHost}/admin/package/${packageId}/ref-pic-upload`,
            {
                method: 'POST',
                body: formData,
            }
        ).then(
            response => response.json
        )
    } else {
        return Promise.resolve(refPicture);
    }
}

export const updatePackage = async (packageId, data, url) => {
    const refPics = data.refPics;
    const editingPics = (data.imageEditingConfigs.map((c) => c.examplePicture) || []).flat();
    const payload = {}

    let refPicTransforms = [];
    let editingPicTransforms = [];

    refPicTransforms = (
        await Promise.all(
            refPics.map(
                (refPicture) => uploadIfNewPicture(refPicture, packageId)
            )
        ).catch(
            (e) => {
                console.error(e);
                return [];
            }
        )
    ).filter((v) => !!v);

    editingPicTransforms = await Promise.all(
        editingPics.map(
            (editingPicture) => uploadIfNewPicture(editingPicture, packageId)
        )
    ).catch(
        (e) => {
            console.error(e);
            return [];
        }
    );

    if (refPicTransforms.length) {
        payload.refPics = refPicTransforms;
    }

    if (editingPicTransforms.length) {
        payload.imageEditingConfigs = data.imageEditingConfigs.map(
            (c, i) => {
                const pic = editingPicTransforms.shift();
                if (c.examplePicture && c.examplePicture.length && c.examplePicture[0].rawFile) {
                    c.examplePicture = pic;
                }
                return c;
            }
        );
    }

    delete data.refPics;
    delete data.imageEditingConfigs;

    if (data['deliveryTime'] && !(['PT_WoltGermany'].includes(data['projectType']))) {
        delete data['deliveryTime']['atTime'];
    }

    Object.keys(data).forEach(
        key => payload[key] = data[key],
    );

    return plainHttpClient(
        url,
        {
            method: 'PATCH',
            headers: new Headers(
                {
                    Accept: "application/json",
                    'Content-Type': "application/json"
                }
            ),
            body: JSON.stringify(payload),
        }
    )
};

export const createPackage = async (data, url) => {
    const refPics = data.refPics || [];
    const imageEditingConfigs = data.imageEditingConfigs || [];
    const editingRefPics = imageEditingConfigs.map((c) => c.examplePicture).flat();
    const payloadCreate = {}

    let refPicTransforms = [];
    let editingPicTransforms = [];

    delete data.refPics;
    delete data.imageEditingConfigs;

    if (data['deliveryTime'] && !(['PT_WoltGermany'].includes(data['projectType']))) {
        delete data['deliveryTime']['atTime'];
    }

    Object.keys(data).forEach(
        key => payloadCreate[key] = data[key],
    );

    const packageCreateResponse = await plainHttpClient(
        url,
        {
            method: 'POST',
            headers: new Headers(
                {
                    Accept: "application/json",
                    'Content-Type': "application/json"
                }
            ),
            body: JSON.stringify(payloadCreate),
        }
    )

    if (!packageCreateResponse || packageCreateResponse.status !== 200 || !packageCreateResponse.json) {
        throw new Error(`No valid response - ${packageCreateResponse && packageCreateResponse.status} ${packageCreateResponse && packageCreateResponse.json}`)
    }

    const packageId = packageCreateResponse.json;
    refPicTransforms = (
        await Promise.all(
            refPics.map(
                (refPicture) => uploadIfNewPicture(refPicture, packageId)
            )
        ).catch(
            (e) => {
                console.error(e);
                return [];
            }
        )
    ).filter((v) => !!v);

    editingPicTransforms = (
        await Promise.all(
            editingRefPics.map(
                (refPicture) => uploadIfNewPicture(refPicture, packageId)
            )
        ).catch(
            (e) => {
                console.error(e);
                return [];
            }
        )
    );

    if (refPicTransforms.length || editingPicTransforms.length) {
        const payloadPatch = {}

        refPicTransforms.length && (payloadPatch.refPics = refPicTransforms);
        editingPicTransforms.length
            && (payloadPatch.imageEditingConfigs = imageEditingConfigs.map(
                (c, i) => {
                    if (c.examplePicture && c.examplePicture.length && c.examplePicture[0].rawFile) {
                        c.examplePicture = editingPicTransforms.shift();
                    }
                    return c;
                }
            ));

        await plainHttpClient(
            url + `/${packageId}`,
            {
                method: 'PATCH',
                headers: new Headers(
                    {
                        Accept: "application/json",
                        'Content-Type': "application/json"
                    }
                ),
                body: JSON.stringify(payloadPatch),
            }
        ).catch(console.error)
    }
};
