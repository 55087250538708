import React from 'react';

import PropTypes from 'prop-types';
import shouldUpdate from 'recompose/shouldUpdate';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import FlatButton from 'material-ui/FlatButton';
import TransformIcon from 'material-ui/svg-icons/image/transform';

import {verifyInterest} from "../actions/plusBrandActions";


const activate = (e, brandId, verifyInterest) => {
    e && e.stopPropagation();
    e && e.preventDefault();

    verifyInterest(brandId);
}

const ActivateButton = ({
    label = 'Aktívál',
    record = {},
    verifyInterest,
}) => (
    <FlatButton
        onClick={(e) => activate(e, record.id, verifyInterest)}
        primary
        label={label}
        icon={<TransformIcon />}
        style={{ overflow: 'inherit' }}
    />
);

ActivateButton.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    verifyInterest: PropTypes.func,
};

const enhance = compose(
    shouldUpdate(
        (props, nextProps) =>
            (props.record &&
                nextProps.record &&
                props.record.id !== nextProps.record.id) ||
            props.basePath !== nextProps.basePath ||
            (props.record == null && nextProps.record != null)
    ),
    connect(null, {verifyInterest})
);

export default enhance(ActivateButton);
