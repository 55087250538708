import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import pure from 'recompose/pure';

const UrlFieldWithTarget = ({ source, record = {}, elStyle, target }) => (
    <a href={get(record, source)} style={elStyle} target={target}>
        {get(record, source)}
    </a>
);

UrlFieldWithTarget.propTypes = {
    addLabel: PropTypes.bool,
    elStyle: PropTypes.object,
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

const PureUrlFieldWithTarget = pure(UrlFieldWithTarget);

PureUrlFieldWithTarget.defaultProps = {
    addLabel: true,
};

export default PureUrlFieldWithTarget;
