export const PROVIDER_PHOTOS_SORTING = 'PROVIDER_PHOTOS_SORTING';
export const PROVIDER_PHOTOS_ORDER_SAVE = 'PROVIDER_PHOTOS_ORDER_SAVE';
export const PROVIDER_PHOTOS_ORDER_SAVE_SUCCESS = 'PROVIDER_PHOTOS_ORDER_SAVE_SUCCESS';
export const PROVIDER_PHOTOS_ORDER_SAVE_FAILURE = 'PROVIDER_PHOTOS_ORDER_SAVE_FAILURE';

export const creativePhotosSortingAction = (creativeId, photos) => ({
  type: PROVIDER_PHOTOS_SORTING,
  payload: { creativeId, photos },
});

export const creativePhotosOrderSaveAction = () => ({
  type: PROVIDER_PHOTOS_ORDER_SAVE,
});
