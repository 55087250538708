import { PROVIDER_PHOTOS_SORTING } from '../actions/creativePhotos';

const initialState = {
    creativeId: null,
    photos: [],
};

export default (previousState = initialState, { type, payload }) => {
    const newState = {...previousState};

    if (type === PROVIDER_PHOTOS_SORTING) {
        newState.creativeId = payload.creativeId;
        newState.photos = payload.photos;

        return newState;
    }

    return previousState;
}
