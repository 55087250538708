import {plainHttpClient} from "./fetch";
import config from "../config";


const sendDataOfEditor = async (data, editorId) => {
    let url = `${config.apiHost}/admin/editor`;
    if (editorId) {
        url = `${config.apiHost}/admin/editor/${editorId}`;
    }

    return plainHttpClient(
        url,
        {
            method: (!editorId)
                ? 'POST'
                : 'PATCH',
            headers: new Headers(
                {
                    Accept: "application/json",
                    'Content-Type': "application/json"
                }
            ),
            body: JSON.stringify(data),
        }
    ).then(
        (response) => response.json,
    )
}

export const updateEditor = async (editorId, data) => {
    return sendDataOfEditor(data, editorId);
}

export const createEditor = async (data) => {
    return sendDataOfEditor(data);
}
