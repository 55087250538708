import React, {Component} from 'react';
import {
    getAllActiveUserEmailFromBrand,
    getAllCreativeEmailFromPackage,
    getAllEditorEmailFromPackage,
} from '../rest/fetch'

class SendEmailButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }

    componentDidMount() {
        if (this.props.type === 'creatives') {
            this.getCreativeEmails();
        }
        if (this.props.type === 'editors') {
            this.getEditorEmails();
        }
        if (this.props.type === 'users') {
            this.getUserEmails();
        }
    }

    getCreativeEmails = async () => {
        try {
            const res = await getAllCreativeEmailFromPackage({packageId: this.props.record.id});
            this.setState({data: res})
        } catch (error) {
            console.error(error);
        }
    }

    getEditorEmails = async () => {
        try {
            const res = await getAllEditorEmailFromPackage({packageId: this.props.record.id});
            this.setState({data: res})
        } catch (error) {
            console.error(error);
        }
    }

    getUserEmails = async () => {
        try {
            const res = await getAllActiveUserEmailFromBrand({clientId: this.props.record.id});
            this.setState({data: res})
        } catch (error) {
            console.error(error);
        }
    }

    render() {
        const {type} = this.props
        const {data} = this.state

        return (
            <div>
                {data &&
                    <div>
                        <button style={{textDecoration: 'none', color: 'inherit', height: '40px'}}>
                            <a
                                style={{
                                    textDecoration: 'none',
                                    color: 'inherit',
                                    height: '100%',
                                    width: '100%',
                                    display: 'block',
                                    padding: '10px 0'
                                }}
                                href={`mailto:${'hello@photon.rocks'}?bcc=${data.join(',')}`}
                            >
                                EMAIL KÜLDÉSE
                            </a>
                        </button>
                        {type === 'creatives' && <p>A csomagon található összes fotós számára.</p>}
                        {type === 'editors' && <p>A csomagon található összes képszerkesztő számára.</p>}
                        {type === 'users' && <p>Az ügyfélhez tartozó összes aktív felhasználó számára.</p>}
                    </div>
                }
            </div>
        );
    }
}

export default SendEmailButton;
