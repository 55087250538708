import React, {Component, Fragment} from 'react';

import PropTypes from 'prop-types';

import FlatButton from 'material-ui/FlatButton';
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import Dialog from 'material-ui/Dialog';
import {
    ReferenceField,
    TextField,
} from "admin-on-rest";
import {plainHttpClient} from "../rest/fetch";
import config from "../config";
import ContentAddCircle from 'material-ui/svg-icons/content/add-circle';



class PlusProjectPhotographer extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            open: false,
            pendingCreative: null
        }
    }

    handleConfirmChange = () => {
        plainHttpClient(
            `${config.apiHost}/enterprise-projects/${this.props.record.id}/update-photographer`,
            {
                method: 'PUT',
                headers: new Headers(
                    {
                        Accept: "application/json",
                        'Content-Type': "application/json"
                    }
                ),
                body: JSON.stringify({photographer: this.state.pendingCreative.id}),
            }
        ).then(
            _ => {document.location.reload();}
        ).catch(e => console.error(e))

        this.setState({pendingCreative: null});
    };

    handleCancelChange = () => {
        this.setState({pendingCreative: null});
    };

    actions = [
        <FlatButton
            label="Mégse"
            primary={true}
            onClick={this.handleCancelChange}
        />,
        <FlatButton
            label="Ok"
            primary={true}
            onClick={this.handleConfirmChange}
        />,
    ];

    componentDidMount() {
        this.loadCreatives();
    }

    loadCreatives() {
        plainHttpClient(`${config.apiHost}/enterprise-packages/${this.props.record.packageId}`)
            .then(response => {
                const idParams = response.json.data.relationships.photographers.data
                    .filter(x => x.id !== this.props.record.photographer_id).map(x => `\`${x.id}\``).join(',')
                return plainHttpClient(`${config.apiHost}/creatives?filter=(id,in,[${idParams}])`)
            })
            .then(x => {
                this.setState({
                    creatives: x.json.data.map(x => ({...x.attributes, id: x.id}))
                });
            })
            .catch(console.error)
    }

    handleSelectCreative = (creative) => {
        this.setState({
            open: false,
            pendingCreative: creative
        });
    };

    handleOpenList = (e) => {
        this.setState({
            open: true,
            anchorEl: e.currentTarget,
        });
    };

    handleRequestClose = () => {
        this.setState({
            open: false,
        });
    };

    render() {
        const {record, source} = this.props;
        return <Fragment>
            {
                record
                && record[source]
                && <ReferenceField
                    addLabel={false}
                    source= {source}
                    record={record}
                    reference="creatives"
                    linkType='show'
                    basePath='/'
                    elStyle={{marginRight: "1em"}}
                >
                    <TextField source='name'/>
                </ReferenceField>
            }
            {
                (["started", "created"].includes(record.status))
                && <FlatButton
                    onClick={this.handleOpenList}
                    label={(
                        record[source]
                        && <img  style ={{height:'25px', width:'25px', marginTop: '5px'}}src = './replace_icon.png'/>)
                        || <ContentAddCircle  style={{ marginTop: '7px',color: 'green' }}/>
                    }
                    style={{overflow: 'inherit', color: 'green'}}
                />
            }
            <Popover
                open={this.state.open}
                anchorEl={this.state.anchorEl}
                anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
                targetOrigin={{horizontal: 'left', vertical: 'top'}}
                onRequestClose={this.handleRequestClose}
            >
                <Menu>
                    {this.state.creatives && this.state.creatives.map(x =>
                        <MenuItem key={x.id} primaryText={x.name} onClick={() => this.handleSelectCreative(x)}/>
                    )}
                </Menu>
            </Popover>
            <Dialog
                title="Valóban lecseréled a projekthez tartozó fotóst?"
                actions={this.actions}
                modal={false}
                open={!!this.state.pendingCreative}
                onRequestClose={this.handleCancelChange}
            >
                {this.state.pendingCreative && this.state.pendingCreative.name}
            </Dialog>
        </Fragment>
    }
}

PlusProjectPhotographer.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string,
};

export default PlusProjectPhotographer;
