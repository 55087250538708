import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FlatButton from 'material-ui/FlatButton';
import { push as pushAction } from 'react-router-redux';
import PhotoCameraIcon from 'material-ui/svg-icons/image/photo-camera';

class PhotosButton extends Component {
  handleClick = () => {
    const { push, record } = this.props;
    push(`/creatives/${record.id}/creative-photos`);
  }

  render() {
    return <FlatButton
      primary
      label="Fényképek"
      onClick={this.handleClick}
      icon={<PhotoCameraIcon />}
      style={{ overflow: 'inherit' }}
      />;
  }
}

PhotosButton.propTypes = {
  basePath: PropTypes.string,
  push: PropTypes.func,
  dispatch: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
};

export default connect(null, {
  push: pushAction,
})(PhotosButton);
