import React from 'react';
import { Route } from 'react-router-dom';
import { CreativePhotoList, CreativePhotoCreate, CreativePhotoDelete } from './resource/CreativePhoto';
import { ReviewList, ReviewShow } from './resource/Review';
import DownloadCreativeCertificates from './components/Tigs/DownloadCreativeCertificates';

export default [
  <Route
    exact
    path="/creatives/:creativeId/creative-photos"
    render={(routeProps) => <CreativePhotoList creativeId={routeProps.match.params.creativeId} {...routeProps}/>}
  />,
  <Route
    exact
    path="/creatives/:creativeId/creative-photos/create"
    render={(routeProps) => <CreativePhotoCreate creativeId={routeProps.match.params.creativeId} {...routeProps}/>}
  />,
  <Route
    exact
    path="/creatives/:creativeId/creative-photos/:id/delete"
    render={(routeProps) => <CreativePhotoDelete {...routeProps}/>}
  />,
  <Route
    exact
    path="/download-tigs"
    render={(routeProps) => <DownloadCreativeCertificates {...routeProps}/>}
  />,
  <Route
    exact
    path="/creatives/:creativeId/reviews"
    render={(routeProps) => <ReviewList creativeId={routeProps.match.params.creativeId} {...routeProps}/>}
  />,
  <Route
    exact
    path="/creatives/:creativeId/reviews/:id/show"
    render={(routeProps) => <ReviewShow creativeId={routeProps.match.params.creativeId} id={routeProps.match.params.id} {...routeProps}/>}
  />,
];
