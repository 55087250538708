import React from 'react';

import PropTypes from 'prop-types';
import shouldUpdate from 'recompose/shouldUpdate';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import FlatButton from 'material-ui/FlatButton';
import {removeCreativeFromProject} from '../actions/plusProjectActions'


const handle = (e, projectId, removeCreativeFromProject) => {
    e && e.stopPropagation();
    e && e.preventDefault();
    removeCreativeFromProject(projectId)
}

const RemovePhotographerFromProjectButton = ({
    label = 'Fotós eltávolítása a projektről',
    record = {},
    removeCreativeFromProject,
  }) => {
    return record.status === 'started' && !!record.photographer_id ? (
      <FlatButton
        onClick={(e) => handle(e, record.id, removeCreativeFromProject)}
        primary
        label={label}
        style={{ overflow: 'inherit', color: 'red' }}
      />
    ) : null;
  };
  
RemovePhotographerFromProjectButton.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    activatePackage: PropTypes.func,
};

const enhance = compose(
    shouldUpdate(
        (props, nextProps) =>
            (props.record &&
                nextProps.record &&
            props.record.photographer_id === nextProps.record.photographer_id)
    ),
    connect(null, {removeCreativeFromProject})
);

export default enhance(RemovePhotographerFromProjectButton);
