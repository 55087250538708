import React from 'react';

import PropTypes from 'prop-types';
import shouldUpdate from 'recompose/shouldUpdate';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import FlatButton from 'material-ui/FlatButton';
import CancelIcon from 'material-ui/svg-icons/navigation/cancel';

import {rejectOwnerChange} from "../actions/plusBrandActions";


const handle = (e, requestId, rejectOwnerChange) => {
    e && e.stopPropagation();
    e && e.preventDefault();

    rejectOwnerChange(requestId);
};

const PlusOwnerChangeRejectButton = (
    {
        label = 'Elutasít',
        record = {},
        rejectOwnerChange,
    }
) => (
    <FlatButton
        onClick={(e) => handle(e, record.id, rejectOwnerChange)}
        primary
        label={label}
        icon={<CancelIcon />}
        style={{ overflow: 'inherit' }}
    />
);

PlusOwnerChangeRejectButton.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    rejectOwnerChange: PropTypes.func,
};

const enhance = compose(
    shouldUpdate(
        (props, nextProps) =>
            (props.record &&
                nextProps.record &&
                props.record.id !== nextProps.record.id) ||
            props.basePath !== nextProps.basePath ||
            (props.record == null && nextProps.record != null)
    ),
    connect(null, {rejectOwnerChange})
);

export default enhance(PlusOwnerChangeRejectButton);
