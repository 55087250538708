import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import PlusCancelByCreativeButton from "./PlusCancelByCreativeButton";

import {
    FunctionField,
    ReferenceField,
    TextField,
} from 'admin-on-rest';
import {pipe} from "../tools";

class Cancellation extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getCancellationType = (event, client) => {
        let cancellationType

        if (client) {
            if (event.details.cancelledBy.type === 'enterprise-users') {
                cancellationType = 'client'
            } else if (event.details.cancelledBy.type === 'creatives') {
                cancellationType = 'creative client nevében'
            } else if (event.details.cancelledBy.type === 'backend-users') {
                cancellationType = 'admin client nevében'
            }
        } else {
            if (event.details.cancelledBy.type === 'creatives') {
                cancellationType = 'creative'
            } else if (event.details.cancelledBy.type === 'backend-users') {
                cancellationType = 'admin creative nevében'
            }
        }

        return cancellationType
    }

    render() {
        const {
            record,
        } = this.props;

        return <div>
            {
                pipe(
                    (events) => events.find(
                        (e) => e.event === 'canceled'
                            && e.details
                            && e.details.cancelledBy
                            && e.details.cancelledBy.type
                    ),
                    (event) => !!event
                        && <div>
                            <p>Lemondás típusa: {this.getCancellationType(event, true)}</p>
                            <p> Lemondó személye:
                                {' '}
                                <ReferenceField
                                    reference={event.details.cancelledBy.type}
                                    record={event.details}
                                    source='cancelledBy.id'
                                    linkType='show'
                                    basePath='/'
                                >
                                    <TextField source="name"/>
                                </ReferenceField>
                            </p>
                            <p>Lemondás oka: {event.details.reason}</p>
                        </div>
                )(record.events)
            }
            {
                pipe(
                    (events) => events.find(
                        (e) => e.event === 'cancelByCreative'
                            && e.details
                            && e.details.cancelledBy
                            && e.details.cancelledBy.type
                    ),
                    (event) => !!event
                        && <div>
                            <p>Lemondás típusa: {this.getCancellationType(event)}</p>
                            <p> Lemondó személye:
                                {' '}
                                <ReferenceField
                                    reference={event.details.cancelledBy.type}
                                    record={event.details}
                                    source='cancelledBy.id'
                                    linkType='show'
                                    basePath='/'
                                >
                                    <TextField source="name"/>
                                </ReferenceField>
                            </p>
                            <p>Lemondás oka: {event.details.reason}</p>
                        </div>
                )(record.events)
            }
            {
                (
                    !record.events.find(
                        (e) => e.event === 'canceled' && (!e.details || !e.details.cancelledBy || !e.details.cancelledBy.type)
                    ) || !record.events.find(
                        (e) => e.event === 'cancelledByCreative' && (!e.details || !e.details.cancelledBy || !e.details.cancelledBy.type)
                    )
                ) && <div>
                    <p>
                        Lemondó {' '}
                        <FunctionField
                            source="cancelledBy_id"
                            record={record}
                            render={
                                (record, source) => {
                                    if (record[source]) {
                                        return <ReferenceField
                                            reference="enterprise-users"
                                            source={source} record={record}
                                            basePath='/'
                                        >
                                            <TextField source="name"/>
                                        </ReferenceField>
                                    }
                                    return <p> --- </p>;
                                }
                            }
                        />
                    </p>

                    <p>
                        Lemondó kreatív {' '}
                        <FunctionField
                            record={record}
                            source="cancelledByCreative_id"
                            render={
                                (record, source) => {
                                    if (record[source]) {
                                        return <ReferenceField
                                            reference="creatives"
                                            source={source}
                                            record={record}
                                            basePath='/'
                                            linkType='show'
                                        >
                                            <TextField source="name"/>
                                        </ReferenceField>
                                    }
                                    return <p> --- </p>;
                                }
                            }
                        />
                    </p>
                    <p> Lemondás oka: {' '}
                        <FunctionField
                            record={record}
                            source="cancellationReason"
                            render={
                                (record, source) => {
                                    if (record[source]) {
                                        return <TextField source={source} record={record}/>
                                    }
                                    return <p> --- </p>;
                                }
                            }
                        />
                    </p>
                </div>
            }

            <FunctionField
                label="Lemondás fotós nevében fotózás időpontja után, leadás előtt"
                source="status"
                record={record}
                render={
                    (record, source) => {
                        if (record[source] === 'retouching') {
                            return <PlusCancelByCreativeButton record={record}/>;

                        }
                    }
                }
            />

        </div>
    }
}

export default withRouter(Cancellation);
