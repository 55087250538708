import {
    CREATE,
    DELETE,
    GET_LIST,
    GET_MANY,
    GET_MANY_REFERENCE,
    GET_ONE,
    JUDGE,
    UPDATE,
} from "./types";

import {
    jsonApiHttpClient,
    plainHttpClient,
    queryParameters
} from "./fetch";
import {
    VERIFY_PLUS_BRAND_INTEREST,
    ACCEPT_PLUS_BRAND_OWNER_CHANGE,
    REJECT_PLUS_BRAND_OWNER_CHANGE,
} from "../actions/plusBrandActions";
import {
    ACTIVATE_PLUS_PACKAGE,
    DEACTIVATE_PLUS_PACKAGE,
    DELETE_PLUS_PACKAGE,
    RESOLVE_MODIFY_PLUS_PACKAGE,
} from "../actions/plusPackageActions";
import {
    createPackage,
    updatePackage,
} from "./enterprisePackageTools";
import AuthClient, {AUTH_GET_USER_ID} from "./authClient";
import {
    CANCEL_PLUS_PROJECT,
    DELETE_EXTRAS_PLUS_PROJECT,
    CANCEL_PLUS_PROJECT_BY_CREATIVE,
    REMOVE_CREATIVE_FROM_PROJECT
} from "../actions/plusProjectActions";
import {createEditor, updateEditor} from "./editorTools";
import {INVITE_EDITOR_AGAIN} from "../actions/editorUserActions";


const mapFilters = (resource, filters) => {
    const queryParams = [];

    if (resource === 'creative-photos') {
        for (let key in filters) {
            queryParams.push([key, 'eq', '`' + String(filters[key]) + '`'])
        }
    }
    if (resource === 'inquiry-packages') {
        for (let key in filters) {
            queryParams.push([key, 'eq', '`' + String(filters[key]) + '`'])
        }
    }
    if (resource === 'reviews') {
        for (let key in filters) {
            queryParams.push([key, 'eq', '`' + String(filters[key]) + '`'])
        }
    }
    if (resource === 'clients') {
        if ('numberOfInquiries' in filters) {
            queryParams.push(['numberOfInquiries', 'gt', '1'])
        }
    }

    if (resource === 'enterprise-projects') {
        if ('status' in filters) {
            queryParams.push(['status', 'eq', '`' + filters.status + '`'])
        }
    }

    return queryParams.map(conditional => '(' + conditional.join(',') + ')');
}

export default (apiUrl, httpClient = jsonApiHttpClient) => {
    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The REST request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertRESTRequestToHTTP = async (type, resource, params) => {
        let url = "";
        const options = {};

        /* eslint-disable no-fallthrough */
        switch (type) {
            case GET_MANY_REFERENCE:
                // falls through
            case GET_LIST:
                if (resource === 'editors') {
                    url = `${apiUrl}/admin/editor/list`;
                    if (params) {
                        const paramsString = JSON.stringify(params);
                        url += `?params=${encodeURIComponent(paramsString)}`;
                    }
                    break;
                }

                if (resource === 'active-editors') {
                    url = `${apiUrl}/admin/editor/list`;

                    const reqParams = {sort: {field: 'name', order: 'DESC'}, filter: {status: 'activeUser'}};

                    const paramsString = JSON.stringify(reqParams);
                    url += `?params=${encodeURIComponent(paramsString)}`;

                    break;
                }

                if (resource === 'active-clients') {
                    url = `${apiUrl}/admin/client/active-list`;
                    break;
                }

                if (resource === 'creatives') {
                    url = `${apiUrl}/creatives?params=${encodeURIComponent(JSON.stringify(params))}`;
                    break;
                }
                if (resource === 'enterprise-packages') {
                    url = `${apiUrl}/enterprise-packages?params=${encodeURIComponent(JSON.stringify(params))}`;
                    break;
                }

                if (resource === 'enterprise-packages') {
                    url = `${apiUrl}/enterprise-packages?params=${encodeURIComponent(JSON.stringify(params))}`;
                    break;
                }

                if (resource === 'enterprise-projects') {
                    url = `${apiUrl}/projects?params=${encodeURIComponent(JSON.stringify(params))}`;
                    break;
                }

                if (resource === 'enterprise-clients') {
                    url = `${apiUrl}/enterprise-clients`;
                    if (params && Object.keys(params).length > 0) {
                        const paramsString = JSON.stringify(params);
                        url += `?params=${encodeURIComponent(paramsString)}`;
                    }
                    break;
                }

                if (resource === 'enterprise-users') {
                    url = `${apiUrl}/enterprise-users`;
                    if (params && Object.keys(params).length > 0) {
                        const paramsString = JSON.stringify(params);
                        url += `?params=${encodeURIComponent(paramsString)}`;
                    }
                    break;
                }

                const {page, perPage} = params.pagination;
                const {field, order} = params.sort;
                let query = {
                    "page[size]": perPage,
                    "page[number]": page
                };
                if (order === "ASC") {
                    query.sort = field;
                } else {
                    query.sort = "-" + field;
                }

                url = `${apiUrl}/${resource}?${queryParameters(query)}`;
                const filters = mapFilters(resource, params.filter);
                if (filters.length > 0) {
                    url = `${url}&filter=${filters.join(',')}`;
                }

                break;
            case GET_ONE:
                if (resource === 'editors' || resource === 'active-editors') {
                    url = `${apiUrl}/admin/editor/${params.id}`;
                    break;
                }

                url = `${apiUrl}/${resource}/${params.id}`;
                break;
            case GET_MANY:
                if (resource === 'editors' || resource === 'active-editors') {
                    url = `${apiUrl}/admin/editor/list`;
                    if (params) {
                      const paramsString = JSON.stringify(params);
                      url += `?params=${encodeURIComponent(paramsString)}`;
                    }
                    options.method = "GET";
                    break;
                }

                const idParams = params.ids.map(id => `\`${id}\``).join(',')
                url = `${apiUrl}/${resource}?filter=(id,in,[${idParams}])`;
                break;

            case UPDATE: {
                let type = resource;
                const keysToUnset = [
                    'id',
                    'createdAt',
                    'updatedAt',
                    'acceptsPrivacyPolicy',
                    'emailVerified',
                    'emailCertified',
                ];

                keysToUnset.forEach(
                    key => {
                        if (key in params.data) {
                            delete params.data[key];
                        }
                    }
                )

                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = "PATCH";

                if (resource === 'enterprise-packages') {
                    await updatePackage(params.id, params.data, url)

                    params.data = {};
                    options.method = "GET";
                    break;
                }

                if (resource === 'editors') {
                    await updateEditor(params.id, params.data);

                    url = `${apiUrl}/admin/editor/${params.id}`;
                    params.data = {};
                    options.method = "GET";
                    break;
                }

                const attrs = {};

                Object.keys(params.data).forEach(
                    key => attrs[key] = params.data[key]
                );

                const updateParams = {
                    data: {type: type, id: params.id, attributes: attrs}
                };
                options.body = JSON.stringify(updateParams);
                break;
            }

            case CREATE: {
                url = `${apiUrl}/${resource}`;
                let type = resource;
                const meta = {};

                if (resource === 'enterprise-packages') {
                    await createPackage(params.data, url);

                    params.data = {};
                    options.method = "GET";
                    break;
                }

                if (resource === 'promo-codes') {
                    params.relationships = {
                        'createdBy': {
                            data: {type: 'backend-users', id: await AuthClient('')(AUTH_GET_USER_ID)}
                        }
                    };
                }

                if (resource === 'editors') {
                    const createdEditorId = await createEditor(params.data);

                    url = `${apiUrl}/admin/editor/${createdEditorId}`;
                    params.data = {};
                    options.method = "GET";
                    break;
                }

                if (resource === 'creative-photos') {
                    if (!params.data.serviceType) {
                        throw new Error('Nincs típus választva.');
                    }
                    if (!Array.isArray(params.data.photos) || params.data.photos.length === 0) {
                        throw new Error('Nincs kép csatolva.');
                    }
                    // multipart/form-data encoding
                    const uploads = [];
                    params.data.photos.forEach(
                        photo => {
                            const formData = new FormData();
                            formData.append('creative_id', params.data.creative);
                            formData.append('service_type', params.data.serviceType);
                            formData.append('photo', photo.rawFile);
                            uploads.push(
                                plainHttpClient(
                                    `${apiUrl}/upload`,
                                    {
                                        method: 'POST',
                                        body: formData,
                                    }
                                ).then(
                                    response => response.json.imagePath
                                ).catch(console.error)
                            );
                        }
                    );

                    await Promise.all(uploads);
                    options.method = "GET";
                    break;
                }

                options.method = "POST";
                const createParams = {
                    data: {type: type, attributes: params.data, meta: meta}
                };
                if (params.relationships) {
                    createParams.data.relationships = params.relationships;
                }
                options.body = JSON.stringify(createParams);
                break;
            }
            case DELETE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = "DELETE";
                break;
            case JUDGE:
                url = `${apiUrl}/${resource}/${params.id}/round/${params.round}/${params.judgement}`;
                options.method = "POST"
                break;
            case VERIFY_PLUS_BRAND_INTEREST:
                url = `${apiUrl}/${resource}/${params.id}/accept`
                options.method = 'PUT';
                break;
            case ACCEPT_PLUS_BRAND_OWNER_CHANGE:
                url = `${apiUrl}/${resource}/${params.id}/accept`
                options.method = 'PUT';
                break;
            case REJECT_PLUS_BRAND_OWNER_CHANGE:
                url = `${apiUrl}/${resource}/${params.id}/reject`
                options.method = 'PUT';
                break;
            case ACTIVATE_PLUS_PACKAGE:
                url = `${apiUrl}/${resource}/${params.id}/activate`
                options.method = 'PUT';
                break;
            case DEACTIVATE_PLUS_PACKAGE:
                url = `${apiUrl}/${resource}/${params.id}/deactivate`
                options.method = 'PUT';
                break;
            case DELETE_PLUS_PACKAGE:
                url = `${apiUrl}/${resource}/${params.id}`
                options.method = 'DELETE';
                break;
            case CANCEL_PLUS_PROJECT:
                url = `${apiUrl}/${resource}/${params.id}/cancel`
                options.method = 'PUT';
                break;
            case CANCEL_PLUS_PROJECT_BY_CREATIVE:
                url = `${apiUrl}/${resource}/${params.id}/cancel-by-creative`
                options.method = 'PUT';
                break;
            case DELETE_EXTRAS_PLUS_PROJECT:
                url = `${apiUrl}/${resource}/${params.id}/delete-extras`
                options.method = 'PUT';
                break;
            case RESOLVE_MODIFY_PLUS_PACKAGE:
                url = `${apiUrl}/${resource}/${params.id}/resolved`
                options.method = 'PUT';
                break;
            case INVITE_EDITOR_AGAIN:
                url = `${apiUrl}/admin/editor/${params.id}/send-invitation/`
                options.method = 'GET';
                break;
            case REMOVE_CREATIVE_FROM_PROJECT:
                url = `${apiUrl}/admin/project/${params.id}/remove-creative`
                options.method = 'POST';
                break;
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
        return {url, options};
    };

    const transformData = (dic) => {
        const interDic = Object.assign(
            {id: dic.id},
            dic.attributes,
            dic.meta
        );
        if (dic.relationships) {
            Object.keys(dic.relationships).forEach(async (key) => {
                const keyString = key + "_id";
                if (dic.relationships[key].data) {
                    if (Array.isArray(dic.relationships[key].data)) {
                        interDic[keyString] = dic.relationships[key].data.map(field => field.id);
                    } else {
                        //if relationships have a data field --> assume id in data field
                        const {id} = dic.relationships[key].data;
                        interDic[keyString] = id;
                    }
                } else if (dic.relationships[key].links) {
                    //if relationships have a link field
                    const link = dic.relationships[key].links["self"];
                    httpClient(link).then(function (response) {
                        interDic[key] = {
                            data: response.json.data,
                            count: response.json.data.length
                        };
                        interDic["count"] = response.json.data.length;
                    });
                }
            });
        }
        return interDic;
    }

    const mapData = (data) => {
        return data.map ? data.map(transformData) : transformData(data);
    }

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The REST request params, depending on the type
     * @returns {Object} REST response
     */
    const convertHTTPResponseToREST = (response, type, resource, params) => {
        const {json} = response;
        switch (type) {
            case GET_LIST:
            case GET_MANY:
            case GET_MANY_REFERENCE:
            case GET_ONE:
            case JUDGE:
                const result = {data: mapData(json.data)};
                if (typeof json.meta === 'object' && json.meta.total) {
                    result.total = json.meta["total"];
                }
                return result;
            case UPDATE:
            case CREATE:
                return {
                    data: Object.assign({id: json.data.id}, json.data.attributes)
                };
            case VERIFY_PLUS_BRAND_INTEREST:
            case ACCEPT_PLUS_BRAND_OWNER_CHANGE:
            case REJECT_PLUS_BRAND_OWNER_CHANGE:
            case ACTIVATE_PLUS_PACKAGE:
            case DEACTIVATE_PLUS_PACKAGE:
            case DELETE_PLUS_PACKAGE:
            case RESOLVE_MODIFY_PLUS_PACKAGE:
            case DELETE:
            case CANCEL_PLUS_PROJECT:
            case CANCEL_PLUS_PROJECT_BY_CREATIVE:
            case INVITE_EDITOR_AGAIN:
            case DELETE_EXTRAS_PLUS_PROJECT:
            case REMOVE_CREATIVE_FROM_PROJECT:
                return {data: {}};
            default:
                throw new Error(`Unsupported fetch response type ${type}`);
        }
    };

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a REST response
     */
    return async (type, resource, params) => {
        const {url, options} = await convertRESTRequestToHTTP(type, resource, params);
        return httpClient(url, options)
            .then(
                (response) => convertHTTPResponseToREST(response, type, resource, params)
            );
    };
};
