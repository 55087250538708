export const ACTIVATE_PLUS_PACKAGE = 'ACTIVATE_PLUS_PACKAGE';
export const DEACTIVATE_PLUS_PACKAGE = 'DEACTIVATE_PLUS_PACKAGE';
export const DELETE_PLUS_PACKAGE = 'DELETE_PLUS_PACKAGE';
export const RESOLVE_MODIFY_PLUS_PACKAGE = 'RESOLVE_MODIFY_PLUS_PACKAGE';


export const activatePackage = (id) => ({
    type: ACTIVATE_PLUS_PACKAGE,
    payload: {id},
    meta: { resource: 'enterprise-packages', fetch: ACTIVATE_PLUS_PACKAGE, cancelPrevious: false },
});

export const deactivatePackage = (id) => ({
    type: DEACTIVATE_PLUS_PACKAGE,
    payload: {id},
    meta: { resource: 'enterprise-packages', fetch: DEACTIVATE_PLUS_PACKAGE, cancelPrevious: false },
});

export const deletePackage = (id) => ({
    type: DELETE_PLUS_PACKAGE,
    payload: {id},
    meta: { resource: 'enterprise-packages', fetch: DELETE_PLUS_PACKAGE, cancelPrevious: false },
});

export const resolveModifyPackage = (id) => ({
    type: RESOLVE_MODIFY_PLUS_PACKAGE,
    payload: {id},
    meta: { resource: 'enterprise-package-modify-requests', fetch: RESOLVE_MODIFY_PLUS_PACKAGE, cancelPrevious: false },
});
