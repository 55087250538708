import React from 'react';

import PropTypes from 'prop-types';
import shouldUpdate from 'recompose/shouldUpdate';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import FlatButton from 'material-ui/FlatButton';
import CancelIcon from 'material-ui/svg-icons/navigation/cancel';

import {cancelPlusProjectByCreative} from "../actions/plusProjectActions";


const handle = (e, requestId, cancelPlusProjectByCreative) => {
    e && e.stopPropagation();
    e && e.preventDefault();

    cancelPlusProjectByCreative(requestId);
}

const PlusProjectCancelButton = (
    {
        label = 'Lemondás Kreatív nevében',
        record = {},
        cancelPlusProjectByCreative,
    }
) => (
    <FlatButton
        onClick={(e) => handle(e, record.id, cancelPlusProjectByCreative)}
        primary
        label={label}
        icon={<CancelIcon />}
        style={{ overflow: 'inherit', color: 'green' }}
    />
);

PlusProjectCancelButton.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    acceptOwnerChange: PropTypes.func,
};

const enhance = compose(
    shouldUpdate(
        (props, nextProps) =>
            (props.record &&
                nextProps.record &&
                props.record.id !== nextProps.record.id) ||
            props.basePath !== nextProps.basePath ||
            (props.record == null && nextProps.record != null)
    ),
    connect(null, {cancelPlusProjectByCreative})
);

export default enhance(PlusProjectCancelButton);
