import React from 'react';
import {
    List,
    ShowButton,
    Datagrid,
    TextField,
    TextInput,
    DateInput,
    Edit,
    SimpleForm,
    Filter,
    FunctionField,
    BooleanField,
    BooleanInput,
    DisabledInput,
    SelectField,
    Show,
    SimpleShowLayout,
    DateField,
    EditButton,
    SelectInput,
    Create,
} from 'admin-on-rest';
import { Field } from 'redux-form';
import {
    Divider,
    Tab,
    Tabs,
    TableHeaderColumn,
    TableRow,
    TableHeader,
    TableRowColumn,
    TableBody,
    Table,
} from 'material-ui';
import CheckIcon from 'material-ui/svg-icons/navigation/check';
import NotYetIcon from 'material-ui/svg-icons/navigation/cancel';

import {
    languages,
    userStates
} from "../constants";
import EditorInviteButton from "../components/EditorInviteButton";


const styles = {
    headline: {
        fontSize: 24,
        paddingTop: 16,
        marginBottom: 12,
        fontWeight: 400,
    },
};

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Név" source="name" />
        <TextInput label="Email" source="email" />
        <SelectInput source="status" choices={userStates} />
    </Filter>
);

export const EditorsList = (props) => (
    <List title="Szerkesztok" {...props} filters={<UserFilter />} >
        <Datagrid>
            <TextField label="Név" source="name"/>
            <SelectField label="Status" source="status" choices={userStates}/>
            <TextField label="Email" source="email"/>
            <TextField label="Telefon" source="phone"/>
            <EditorInviteButton />

            <BooleanField />
            <FunctionField
                label='Aláírt'
                source='tandc'
                render={
                    (record, source) => <span>
                        {
                            !record[source]
                                ? <NotYetIcon />
                                : <CheckIcon />
                        }
                    </span>
                }
            />
            <TextField label='Ország' source='editor.billing.address.country'/>

            <ShowButton />
        </Datagrid>
    </List>
);

export const EditorShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <EditButton />
            <TextField label="Név" source="name"/>
            <TextField label="Status" source="status"/>
            <TextField label="Email" source="email"/>
            <TextField label="Telefon" source="phone"/>
            <EditorInviteButton />

            <BooleanField label='Hirlevel' source='newsletter'/>
            <BooleanField label='Visszaigazolt' source='emailConfirmed'/>
            <BooleanField label='Adatvédelmi Nyilatkozat' source='pn'/>
            <BooleanField label='ÁSZF' source='tandc'/>

            {/* tigs */}
            <h2 style={styles.headline}>Teljesítési igazolások</h2>
            <Divider />
            <FunctionField
                label="Teljesítési igazolások"
                render={
                    record => {
                        return record.tigs && record.tigs.length > 0
                            ? <div style={styles.wrapper}>
                                <Table selectable={false}>
                                    <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                                        <TableRow>
                                            <TableHeaderColumn>Dátum (elszámolt hónap)</TableHeaderColumn>
                                            <TableHeaderColumn>Teljesítési igazolás</TableHeaderColumn>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody displayRowCheckbox={false}>
                                        {
                                            record.tigs.map(
                                                tig => {
                                                    const startDate = new Date(Date.parse(tig.date))
                                                    return <TableRow>
                                                        <TableRowColumn>{startDate.getFullYear()} - {String(startDate.getMonth() + 1).padStart(2, '0')}</TableRowColumn>
                                                        <TableRowColumn><a href={tig.url} target="_blank" rel="noreferrer">Letöltés</a></TableRowColumn>
                                                    </TableRow>
                                                }
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                            : <div>Nincsenek teljesítési igazolások</div>
                    }
                }
            />

            <h2 style={styles.headline}>Kiállított számlák</h2>
            <Divider />
            <FunctionField
                label="Teljesítési igazolások"
                render={
                    record => {
                        return record.invoices && record.invoices.length > 0
                            ? <div style={styles.wrapper}>
                                <Table selectable={false}>
                                    <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                                        <TableRow>
                                            <TableHeaderColumn>Dátum (elszámolt hónap)</TableHeaderColumn>
                                            <TableHeaderColumn>Számlakép</TableHeaderColumn>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody displayRowCheckbox={false}>
                                        {
                                            record.invoices.map(
                                                tig => {
                                                    const startDate = new Date(Date.parse(tig.time))
                                                    return <TableRow>
                                                        <TableRowColumn>{startDate.getFullYear()} - {startDate.getMonth() + 1}</TableRowColumn>
                                                        <TableRowColumn><a href={tig.url} target="_blank" rel="noreferrer">Letöltés</a></TableRowColumn>
                                                    </TableRow>
                                                }
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                            : <div>Nincsenek számlák</div>
                    }
                }
            />

            <h2 style={styles.headline}>Számlázási adatok</h2>
            <Divider />
            <TextField label='Számlázási név' source={'editor.billing.name'}/>
            <TextField label='Ország' source='editor.billing.address.country'/>
            <TextField label='Irányítószám' source='editor.billing.address.zipCode'/>
            <TextField label='Város' source='editor.billing.address.city'/>
            <TextField label='Utca' source='editor.billing.address.street'/>

            <TextField label='Adoszam' source='editor.billing.taxNumber'/>

            <TextField label='Számlaszám Giro' source='editor.billing.bankAccountGiro'/>
            <TextField label='Számlaszám IBAN' source='editor.billing.bankAccountIban'/>
            <TextField label='Bank Swift' source='editor.billing.bankSwift'/>
            <TextField label='Bank neve' source='editor.billing.bankName'/>

            <SelectField label="Számlázási nyelv" source='editor.billing.lang' choices={languages}/>

            <DateField label="Regisztráció dátuma" source="createdAt" locales="hu-HU" showTime />
            <EditButton />
        </SimpleShowLayout>
    </Show>
)

const BankAccNumberInput = () => (
    <Tabs>
        <Tab label="Giro" value="a" addField>
            <div>
                <Field name="editor.billing.bankAccountGiro" component={TextInput} label="Giro szamlaszam" />
            </div>
        </Tab>
        <Tab label="IBAN" value="b">
            <div>
                <p>
                    <Field name="editor.billing.bankAccountIban" component={TextInput} label='IBAN szamlaszam' />
                </p>
                <p>
                    <Field name="editor.billing.bankSwift" component={TextInput} label='Bank Swift kod' />
                </p>
            </div>
        </Tab>
    </Tabs>
);
BankAccNumberInput.defaultProps = {
    addLabel: true,
    label: 'Bank szamlaszam',
}

export const EditorEdit = (props) => <Edit {...props}>
    <SimpleForm>
        <TextInput label="Név" source="name"/>
        <SelectInput label="Status" source="status" choices={userStates}/>
        <TextInput label="Telefon" source="phone"/>

        <h2 style={styles.headline}>Email adatok</h2>
        <Divider />
        <TextInput label="Email" source="email"/>
        <BooleanInput label='Hirlevel' source='newsletter'/>
        <DisabledInput disabled label='Visszaigazolt' source='emailConfirmed'/>

        <h2 style={styles.headline}>Számlázási adatok</h2>
        <Divider />
        <TextInput label='Megnevezes' source='editor.billing.name'/>
        <TextInput label='Adoszam' source='editor.billing.taxNumber'/>
        <TextInput label='Ország' source='editor.billing.address.country'/>
        <TextInput label='Varos' source='editor.billing.address.city'/>
        <TextInput label='Utca' source='editor.billing.address.street'/>
        <TextInput label='Iranyitoszam' source='editor.billing.address.zipCode'/>

        <h2 style={styles.headline}>Bank adatok</h2>
        <TextInput label='Bank nev' source='editor.billing.bankName'/>

        <BankAccNumberInput />

        <h2 style={styles.headline}>Meta</h2>
        <Divider />
        <DisabledInput label="Regisztráció dátuma" source="createdAt"/>
    </SimpleForm>
</Edit>;

export const EditorCreate = (props) => <Create {...props}>
    <SimpleForm>
        <TextInput label="Név" source="name"/>
        <TextInput label="Telefon" source="phone"/>

        <h2 style={styles.headline}>Email adatok</h2>
        <Divider />
        <TextInput label="Email" source="email"/>

        <h2 style={styles.headline}>Számlázási adatok</h2>
        <Divider />
        <TextInput label='Megnevezes' source='editor.billing.name'/>
        <TextInput label='Adoszam' source='editor.billing.taxNumber'/>
        <TextInput label='Ország' source='editor.billing.address.country'/>
        <TextInput label='Varos' source='editor.billing.address.city'/>
        <TextInput label='Utca' source='editor.billing.address.street'/>
        <TextInput label='Iranyitoszam' source='editor.billing.address.zipCode'/>
        <SelectInput label='Számlázási nyelv' source='editor.billing.lang' choices={languages} />

        <h2 style={styles.headline}>Bank adatok</h2>
        <TextInput label='Bank nev' source='editor.billing.bankName'/>

        <BankAccNumberInput />

    </SimpleForm>
</Create>;
