export const PROVIDER_ACCEPT = 'PROVIDER_ACCEPT';
export const PROVIDER_REJECT = 'PROVIDER_REJECT';

export const creativeAccept = (id, round) => ({
  type: PROVIDER_ACCEPT,
  payload: { id, judgement: 'accept', round },
  meta: { resource: 'creatives', fetch: 'JUDGE', cancelPrevious: false },
});

export const creativeReject = (id, round) => ({
  type: PROVIDER_REJECT,
  payload: { id, 'judgement': 'reject', round },
  meta: { resource: 'creatives', fetch: 'JUDGE', cancelPrevious: false },
});
