import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  SelectField,
  SelectInput,
} from 'admin-on-rest';


const statuses = [
  { id: 'deactivated', name: 'Inaktív' },
  { id: 'active', name: 'Aktív' },
];

export const PhotoroomTemplateList = (props) => (
  <List
    title="Photoroom szerkesztési sablonok"
    resource="photoroom-editing-templates"
    {...props}
    perPage={25}
    hasCreate={true}
    sort={{ field: 'name,status', order: 'ASC,ASC' }}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="templateId" />
      <SelectField label="Elérhető?" source="status" choices={statuses} />
      <EditButton />
    </Datagrid>
  </List>
);

export const PhotoroomTemplateForm = (props) => <SimpleForm redirect="list" {...props}>
  <TextInput label="Elnevezés" source="name" />
  {
      props.creating
      && <TextInput label="Sablon kód" source="templateId" />
  }
  <SelectInput label="Elérhető?" source="status" choices={statuses} />
</SimpleForm>

export const PhotoroomTemplateEdit = (props) => (
    <Edit title="Photoroom szerkesztési sablon szerkesztés" {...props} >
      <PhotoroomTemplateForm />
    </Edit>
);

export const PhotoroomTemplateCreate = (props) => (
    <Create title="Photoroom szerkesztési sablon létrehozás" {...props} >
      <PhotoroomTemplateForm creating />
    </Create>
);
