import React from 'react';
import {
    List,
    ShowButton,
    Datagrid,
    TextField,
    TextInput,
    Edit,
    SimpleForm,
    Filter,
    BooleanField,
    BooleanInput,
    DisabledInput,
    ReferenceInput,
    FunctionField,
    SelectField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    DateField,
    EditButton,
    SelectInput
} from 'admin-on-rest';
import {
    userRoles,
    userStates
} from "../constants";


const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Név" source="name" />
        <TextInput label="Email" source="email" />
        <SelectInput source="status" choices={userStates} />
    </Filter>
);

export const PlusUsersList = (props) => (
    <List title="Plus felhasználók" {...props} filters={<UserFilter />} >
        <Datagrid>
            <TextField label="Név" source="name"/>
            <SelectField label="Status" source="status" choices={userStates}/>
            <TextField label="Email" source="email"/>
            <TextField label="Telefon" source="phone"/>

            <ReferenceField label="Cég" source="plus.brand.id" reference="enterprise-clients">
                <TextField source="name" />
            </ReferenceField>

            <SelectField label='Jogosultság' source="plus.brand.role" choices={userRoles} />

            <BooleanField label='Hirlevel' source='newsletter'/>
            <BooleanField label='Visszaigazolt' source='emailConfirmed'/>
            <BooleanField label='ASZF' source='tandc'/>

            <DateField label="Regisztráció dátuma" source="createdAt" locales="hu-HU" showTime />

            <ShowButton />
            <FunctionField
                label="Aktíválás"
                source="adminVerified"
                render={
                    (record, source) => {
                        if (record['status'] === 'deletedUser') {
                            return <p>Már törölt</p>
                        } else {
                            return <EditButton/>
                        }
                    }
                }
            />
        </Datagrid>
    </List>
);

export const PlusUserShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField label="Név" source="name"/>
            <TextField label="Status" source="status"/>
            <TextField label="Email" source="email"/>
            <TextField label="Telefon" source="phone"/>

            <BooleanField label='Hirlevel' source='newsletter'/>
            <BooleanField label='Visszaigazolt' source='emailConfirmed'/>
            <BooleanField label='ASZF' source='tandc'/>

            <ReferenceField label="Cég" source="plus.brand.id" reference="enterprise-clients">
                <TextField source="name" />
            </ReferenceField>
            <SelectField label='Jogosultság' source="plus.brand.role" choices={userRoles} />

            <DateField label="Regisztráció dátuma" source="createdAt" locales="hu-HU" showTime />
        </SimpleShowLayout>
    </Show>
)

export const PlusUserEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput label="Név" source="name"/>
            <SelectInput label="Status" source="status" choices={userStates}/>
            <TextInput label="Email" source="email"/>
            <TextInput label="Telefon" source="phone"/>

            <BooleanInput label='Hirlevel' source='newsletter'/>
            <BooleanInput label='Visszaigazolt' source='emailConfirmed'/>

            <ReferenceInput label="Cég" source="plus.brand.id" reference="enterprise-clients">
                <DisabledInput source="name" />
            </ReferenceInput>
            <SelectInput label='Jogosultság' source="plus.brand.role" choices={userRoles} />

            <DisabledInput label="Regisztráció dátuma" source="createdAt"/>
        </SimpleForm>
    </Edit>
)
