import React, {Component} from 'react';
import {getPackage, updateProjectPrices} from '../rest/fetch'
import {withRouter} from 'react-router-dom';
import {
  ReferenceField,
  TextField,
} from "admin-on-rest";


class PriceCorrection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientPrice: this.props.record.clientPrice,
      creativePrice: this.props.record.creativePrice,
      editorPrice: this.props.record.editorPrice,
      clientCancellationFee: this.props.record.clientCancellationFee,
      creativeFee: null,
      creativeLateFee: null,

      clientPriceChangeReason: null,
      creativePriceChangeReason: null,
      editorPriceChangeReason: null,
      creativeFeeChangeReason: null,
      creativeLateFeeChangeReason: null,
      clientCancellationFeeChangeReason: null,

      validationMessage: '',
      relatedPackage: null,
    };
  }

  getCreativeCancellationEvents() {
    return this.props.record.events.filter(({event}) => event === 'cancelByCreative');
  }

  getCreativeLateFeeEvents() {
    return this.props.record.events.filter(({event}) => event === 'creativeLateFee');
  }

  async componentDidMount() {
    try {
      const res = await getPackage({packageId: this.props.record.packageId});
      this.setState({relatedPackage: res})
    } catch (error) {
      console.error(error);
    }
  }

  async savePriceChanges(currentPrice, updatedPrice, changeReason, type, eventId) {
    if (currentPrice === updatedPrice || updatedPrice < 0) {
      this.setState({validationMessage: 'A megváltoztatott összegnek el kell térnie az eredetitől'})
      return
    }

    if (!changeReason) {
      this.setState({validationMessage: 'Árváltoztatás esetén indoklás megadása kötelező'})
      return
    }

    const data = {
      updatedPrice,
      changeReason,
      type,
      eventId
    }

    const projectId = this.props.record.id
    await updateProjectPrices({data, projectId})

    this.setState({validationMessage: `${type}: ${currentPrice} --> ${updatedPrice}`})
    // this.props.history.push(`/enterprise-projects/${projectId}/show`);
  }

  render() {
    const {
      clientPrice,
      creativePrice,
      editorPrice,
      clientCancellationFee,
      creativeFee,
      creativeLateFee,

      clientPriceChangeReason,
      creativePriceChangeReason,
      editorPriceChangeReason,
      creativeFeeChangeReason,
      creativeLateFeeChangeReason,
      clientCancellationFeeChangeReason,

      validationMessage,
      relatedPackage
    } = this.state;

    const {
      record,
    } = this.props;

    const currency = record && record.price && record.price.currency;
    const creativeCancellationEvents = this.getCreativeCancellationEvents();
    const creativeLateFeeEvents = this.getCreativeLateFeeEvents();

    return (
      <div>
        <div>
          <h3 style={{margin: '10px'}}>Díjazás</h3>
          {
            record.status !== 'canceled'
            && <div style={{padding: '10px'}}>
              <p style={{marginRight: "10px"}}>Projekt díj:</p>
              <div style={{display: "flex", flexDirection: 'row', alignItems: 'center'}}>
                <p style={{
                  marginRight: "10px",
                  width: '100px'
                }}>{record.clientPrice || 0} {currency}</p>

                <input
                  style={{height: "30px"}}
                  type='number'
                  placeholder={'Új ár'}
                  onChange={(e) => this.setState({clientPrice: Number(e.target.value)})}
                >
                </input>
                <input
                  style={{width: '500px', marginLeft: '10px', height: '30px'}}
                  type='text'
                  placeholder={'változtatás indokása'}
                  onChange={(e) => this.setState({clientPriceChangeReason: e.target.value})}
                >
                </input>
                <button
                  onClick={() => this.savePriceChanges(record.clientPrice, clientPrice, clientPriceChangeReason, 'clientPrice')}
                  style={{margin: '10px', height: '30px'}}
                >
                  Projekt díj mentése
                </button>
              </div>
            </div>
          }

          {
            record.photographer_id
            && <div style={{padding: ' 10px'}}>
              <p style={{marginRight: "10px"}}>{`Fotós ár:`}</p>
              <div style={{display: "flex", flexDirection: 'row', alignItems: 'center'}}>
                <p style={{
                  marginRight: "10px",
                  width: '100px'
                }}>{record.creativePrice || 0} {currency}</p>
                <input
                  style={{height: "30px"}}
                  type='number'
                  placeholder={'Új ár'}
                  onChange={(e) => this.setState({creativePrice: e.target.value})}
                >
                </input>
                <input
                  style={{width: '500px', marginLeft: '10px', height: '30px'}}
                  type='text'
                  placeholder={'változtatás indokása'}
                  onChange={(e) => this.setState({creativePriceChangeReason: e.target.value})}
                >
                </input>
                <button
                  onClick={() => this.savePriceChanges(record.creativePrice, creativePrice, creativePriceChangeReason, 'creativePrice')}
                  style={{margin: '10px', height: '30px'}}> Fotós ár mentése
                </button>
              </div>
            </div>
          }
          {
            relatedPackage
            && relatedPackage.manualEditingEnabled
            && <div style={{padding: ' 10px'}}>
              <p style={{marginRight: "10px"}}>{`Képszerkesztő díj:`}</p>
              <div style={{display: "flex", flexDirection: 'row', alignItems: 'center'}}>
                <p style={{
                  marginRight: "10px",
                  width: '100px'
                }}>{record.editorPrice || 0} {currency}</p>

                <input
                  style={{height: "30px"}}
                  type='number'
                  placeholder={'Új ár'}
                  onChange={(e) => this.setState({editorPrice: e.target.value})}
                >
                </input>
                <input
                  style={{width: '500px', marginLeft: '10px', height: '30px'}}
                  type='text'
                  placeholder={'változtatás indokása'}
                  onChange={(e) => this.setState({editorPriceChangeReason: e.target.value})}
                >
                </input>
                <button
                  onClick={() => this.savePriceChanges(record.editorPrice, editorPrice, editorPriceChangeReason, 'editorPrice')}
                  style={{margin: '10px', height: '30px'}}> Képszerkesztő díj mentése
                </button>
              </div>
            </div>
          }

          {
            record.photographer_id
            && record.status === 'canceled'
            && <div style={{padding: ' 10px'}}>
              <p style={{marginRight: "10px"}}>{`Fotós kötbér:`}</p>
              {
                creativeCancellationEvents.length > 0
                  ? creativeCancellationEvents.map(
                    ({details, _id}) => <div
                      key={_id}
                      style={{display: "flex", flexWrap: 'wrap'}}
                    >
                      <span>{details.creativeFee} {details.creativeFeeCurrency}</span>
                      <span style={{marginRight: "10px"}}>({details.creativeId})</span>
                      <input
                        style={{height: '30px'}}
                        type='number'
                        placeholder={'Új ár'}
                        onChange={(event) => this.setState({creativeFee: event.target.value})}
                      />
                      <textarea
                        style={{minWidth: '300px', marginLeft: '10px'}}
                        placeholder={'változtatás indokása'}
                        onChange={(event) => this.setState({creativeFeeChangeReason: event.target.value})}
                      />
                      <button
                        onClick={() => this.savePriceChanges(details.creativeFee, creativeFee, creativeFeeChangeReason, 'creativeFeeCorrection', _id)}
                        style={{margin: '10px', height: '30px'}}> Fotós kötbér mentése
                      </button>
                    </div>
                  ) : <div style={{display: "flex", flexWrap: 'wrap'}}>
                    <span style={{marginRight: "10px"}}> Kötbér létrehozása </span>
                    <input
                      style={{height: '30px'}}
                      type='number'
                      placeholder={'Új ár'}
                      onChange={(event) => this.setState({creativeFee: event.target.value})}
                    />
                    <textarea
                      style={{minWidth: '300px', marginLeft: '10px'}}
                      placeholder={'változtatás indokása'}
                      onChange={(event) => this.setState({creativeFeeChangeReason: event.target.value})}
                    />
                    <button
                      onClick={() => this.savePriceChanges(0, creativeFee, creativeFeeChangeReason, 'creativeFeeCorrection')}
                      style={{margin: '10px', height: '30px'}}
                    >
                      Fotós kötbér mentése
                    </button>
                  </div>
              }
            </div>
          }

          <div style={{padding: ' 10px'}}>
            <p style={{marginRight: "10px"}}>{`Fotós késői leadás kötbér:`}</p>
            {
              creativeLateFeeEvents.length > 0
                ? creativeLateFeeEvents.map(
                  ({details, _id}) => <div
                    key={_id}
                    style={{display: "flex", flexWrap: 'wrap'}}
                  >
                    <span>{details.creativeFee} {details.creativeFeeCurrency}&nbsp;</span>
                    <span style={{marginRight: "10px"}}>
                      (<ReferenceField
                        reference='creatives'
                        record={details}
                        source='creativeId'
                        linkType='show'
                        basePath='/'
                      >
                        <TextField source="name"/>
                      </ReferenceField>)
                    </span>
                    <input
                      style={{height: '30px'}}
                      type='number'
                      placeholder={'Új ár'}
                      onChange={(event) => this.setState({creativeLateFee: event.target.value})}
                    />
                    <textarea
                      style={{minWidth: '300px', marginLeft: '10px'}}
                      placeholder={'változtatás indokása'}
                      onChange={(event) => this.setState({creativeLateFeeChangeReason: event.target.value})}
                    />
                    <button
                      onClick={() => this.savePriceChanges(details.creativeFee, creativeLateFee, creativeLateFeeChangeReason, 'creativeLateFeeCorrection', _id)}
                      style={{margin: '10px', height: '30px'}}> Fotós késői leadás kötbér mentése
                    </button>
                  </div>
                ) : <div style={{display: "flex", flexWrap: 'wrap'}}>
                  <span style={{marginRight: "10px"}}> Késői leadás kötbér létrehozása </span>
                  <input
                    style={{height: '30px'}}
                    type='number'
                    placeholder={'Új ár'}
                    onChange={(event) => this.setState({creativeLateFee: event.target.value})}
                  />
                  <textarea
                    style={{minWidth: '300px', marginLeft: '10px'}}
                    placeholder={'változtatás indokása'}
                    onChange={(event) => this.setState({creativeLateFeeChangeReason: event.target.value})}
                  />
                  <button
                    onClick={() => this.savePriceChanges(0, creativeLateFee, creativeLateFeeChangeReason, 'creativeLateFeeCorrection')}
                    style={{margin: '10px', height: '30px'}}
                  >
                    Fotós késői leadás kötbér mentése
                  </button>
                </div>
            }
          </div>

          {
            record.status === 'canceled'
            && <div style={{padding: ' 10px'}}>
              <p style={{marginRight: "10px"}}>{`Ügyfél általi projekt lemondási díj:`}</p>
              <div style={{display: "flex", flexDirection: 'row', alignItems: 'center'}}>
                <p style={{marginRight: "10px", width: '100px'}}>{record.clientCancellationFee || 0} {currency}</p>
                <input
                  style={{height: "30px"}}
                  type='number'
                  placeholder={'Új ár'}
                  onChange={(e) => this.setState({clientCancellationFee: e.target.value})}
                >
                </input>
                <input
                  style={{width: '500px', marginLeft: '10px', height: '30px'}}
                  type='text'
                  placeholder={'változtatás indokása'}
                  onChange={(e) => this.setState({clientCancellationFeeChangeReason: e.target.value})}
                >
                </input>
                <button
                  onClick={() => this.savePriceChanges(record.clientCancellationFee, clientCancellationFee, clientCancellationFeeChangeReason, 'clientCancellationFeeCorrection')}
                  style={{margin: '10px', height: '30px'}}> Lemondási díj mentése
                </button>
              </div>
            </div>
          }

          <p style={{margin: "10px"}}> {validationMessage}</p>
        </div>
      </div>
    );
  }
}

export default withRouter(PriceCorrection);
