import React from 'react';
import { connect } from 'react-redux';
import { List, CreateButton, RefreshButton } from 'admin-on-rest';
import { CardActions } from 'material-ui/Card';
import FlatButton from 'material-ui/FlatButton';
import SaveIcon from 'material-ui/svg-icons/content/save';
import CreativePhotoGrid from './CreativePhotoGrid';
import { creativePhotosOrderSaveAction } from '../../actions/creativePhotos';

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

const mapStateToActionsProps = (state) => ({
  saveEnabled: state.creativePhotos.photos.length > 0
});

const mapDispatchToActionsProps = {
  creativePhotosOrderSaveAction
};

const ListActions = ({ resource, filters, displayedFilters, filterValues, basePath, showFilter, saveEnabled, creativePhotosOrderSaveAction }) => (
  <CardActions style={cardActionStyle}>
    {filters && React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' }) }
    <FlatButton primary label="Sorrend mentése" disabled={!saveEnabled} icon={<SaveIcon />} onClick={creativePhotosOrderSaveAction} />
    <CreateButton basePath={basePath} />
    <RefreshButton />
  </CardActions>
);

const ListActionsConnected = connect(mapStateToActionsProps, mapDispatchToActionsProps)(ListActions);

export default (props) => (
  <List
    {...props}
    title="Fényképek"
    resource="creative-photos"
    perPage={25}
    hasCreate={true}
    actions={<ListActionsConnected />}
    filter={{
      creative: props.creativeId
    }}
    sort={{ field: 'order', order: 'ASC' }}
  >
    <CreativePhotoGrid creativeId={props.creativeId} />
  </List>
)
