import React from 'react';

import PropTypes from 'prop-types';
import shouldUpdate from 'recompose/shouldUpdate';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import FlatButton from 'material-ui/FlatButton';
import TransformIcon from 'material-ui/svg-icons/image/transform';

import {resolveModifyPackage} from "../actions/plusPackageActions";


const activate = (e, modifyRequestId, resolveModifyPackage) => {
    e && e.stopPropagation();
    e && e.preventDefault();

    resolveModifyPackage(modifyRequestId);
}

const PlusPackageModifyResolveButton = (
    {
        label = 'Megold',
        record = {},
        resolveModifyPackage,
    }
) => <FlatButton
    onClick={(e) => activate(e, record.id, resolveModifyPackage)}
    primary
    label={label}
    icon={<TransformIcon />}
    style={{ overflow: 'inherit' }}
/>;

PlusPackageModifyResolveButton.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    verifyInterest: PropTypes.func,
};

const enhance = compose(
    shouldUpdate(
        (props, nextProps) =>
            (props.record &&
                nextProps.record &&
                props.record.id !== nextProps.record.id) ||
            props.basePath !== nextProps.basePath ||
            (props.record == null && nextProps.record != null)
    ),
    connect(null, {resolveModifyPackage})
);

export default enhance(PlusPackageModifyResolveButton);
