import React from 'react';
import {
    List,
    ShowButton,
    Datagrid,
    TextField,
    FunctionField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    DateField,
    Edit,
    SimpleForm,
    TextInput,
    EditButton,
    AutocompleteInput,
    SelectInput,
    BooleanInput,
    BooleanField,
    Filter,
    SelectArrayInput,
} from 'admin-on-rest';
import Chip from 'material-ui/Chip';

import PlusBrandActivateButton from "../components/PlusBrandActivateButton";
import UrlFieldWithTarget from "../components/UrlFieldWithTarget";
import countryCodes from "../countryCodes";
import PlusOwnerChangeAcceptButton from "../components/PlusOwnerChangeAcceptButton";
import PlusOwnerChangeRejectButton from "../components/PlusOwnerChangeRejectButton";
import DeleteButton from "admin-on-rest/lib/mui/button/DeleteButton";
import { languages } from '../constants';
import SendEmailButton from '../components/SendEmailButton'
import {partnerCountryCodes} from '../constants'

// const countryNames = (require('localized-countries')(require('localized-countries/data/hu.json'))).object()
import localizedCountries from 'localized-countries';
import hu from 'localized-countries/data/hu.json';

const styles = {
    chip: {
        margin: 4,
    },
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
};

const countryNames = localizedCountries(hu).object();
const PlusBrandFilter = (props) => (
    <Filter {...props}>
        <BooleanInput label="Csak aktivált ügyfelek" source="adminVerified"/>
    </Filter>
);

const BrandTitle = ({ record }) => {
    return <div><h1>Cég: {record.name}</h1><p>Adószám: <b>{record.taxId}</b></p><p>Id: {record.id}</p></div>;
};

const optionRenderer = choice => {
    return choice.name === "aor.input.autocomplete.none" ? "Nincs megadva" : choice.name;
};

export const BrandEdit = (props) => (
    <Edit title={<BrandTitle />} {...props}>
        <SimpleForm>
            <TextInput label='Név' source="name" />
            <TextInput label='Cim' source='location' />
            <SelectInput label="Ország" source="country" choices={partnerCountryCodes}/>

            <hr/>
            <h3>Tulajdonos kapcsolat</h3>
            <TextInput label='Név' source="legalContact.name" />
            <TextInput label='Email' source='legalContact.email' />
            <TextInput label='Tel.' source='legalContact.phone' />

            <hr/>
            <h3>Számlázás</h3>
            <AutocompleteInput
                label='Ország'
                source="billingAddress.countryCode"
                choices={countryCodes.map(x => ({id: x, name: countryNames[x]})).filter(x => x.name)}
                optionText={optionRenderer}
                allowEmpty={true}
                translateChoice={false}
            />
            <TextInput label='Irányítószám' source='billingAddress.zipCode' />
            <TextInput label='Város' source='billingAddress.city' />
            <TextInput label='Cím' source='billingAddress.address' />
            <SelectArrayInput label="Számlázási emailek" source="billingEmails" />
            <hr/>
            <SelectInput
                source="billingSettings.lang"
                label="Számlázási nyelv"
                choices={languages}
                optionText={(choice) => `${choice.name}`}
            />

            <BooleanInput label="Chat engedélyezve" source='chatEnabled'/>
        </SimpleForm>
    </Edit>
);

const modifyRowStyle = (record, index) => ({
    backgroundColor: (
        record.resolved
        && (
            (record.resolved.result === 'accepted' && 'aquamarine')
            || (record.resolved.result === 'rejected' && 'lightpink')
        )
    ) || 'white',
});

export const BrandOwnerModifyList = (props) => <List
    title="Plus cégtulajdonos módosítások"
    sort={{ field: 'createdAt', order: 'DESC' }}
    {...props}
>
    <Datagrid rowStyle={modifyRowStyle}>
        <DateField label="Létrehozva" source="createdAt" showTime />
        <ReferenceField source='client_id' label='Kapcsolódó cég' reference='enterprise-clients'>
            <TextField source='name' />
        </ReferenceField>
        <ReferenceField source='owner_id' label='Jelenlegi tulajdonos' reference='enterprise-users'>
            <TextField source='name' />
        </ReferenceField>
        <ReferenceField source='promoted_id' label='Kijelölt tulajdonos' reference='enterprise-users'>
            <TextField source='name' />
        </ReferenceField>
        <FunctionField
            label="Befejezve"
            source="resolved"
            render={
                (record, source) => {
                    if (record[source]) {
                        const value = record[source];

                        return <span>
                            <TextField source='result' record={value} />
                            <br/>
                            <ReferenceField source='by' record={value} reference="backend-users" basePath="/" linkType='show'>
                                <TextField label='Engedélyező' source='name' />
                            </ReferenceField>
                            <br/>
                            <DateField source='time' record={value} />
                        </span>
                    } else {
                        return <React.Fragment>
                            <PlusOwnerChangeAcceptButton record={record} />
                            <PlusOwnerChangeRejectButton record={record} />
                        </React.Fragment>
                    }
                }
            }
        />
        <DeleteButton />
    </Datagrid>
</List>;

export const BrandList = (props) => (
    <List 
        title="Plus cégei" {...props}
        filters={<PlusBrandFilter/>}
        {...props}
    >
        <Datagrid>
            <TextField label="Cég neve" source="name"/>
            <TextField label="Adószám" source="taxId"/>
            <FunctionField
                label="Jogi kapcsolat"
                source="legalContact"
                render={
                    (record, source) => <span {...props}>
                        {record[source].name} - (<a href={`mailto:${record[source].email}`}>{record[source].email}</a>, {record[source].phone})
                    </span>
                }
            />
            <FunctionField
                label="Aktíválás"
                source="adminVerified"
                render={
                    (record, source) => {
                        if (record[source]) {
                            const value = record[source];

                            return <span>
                                <ReferenceField source='checkedBy' record={value} reference="backend-users" basePath="/" linkType='show'>
                                    <TextField label='Engedélyező' source='name' />
                                </ReferenceField>
                                <br/>
                                <DateField source='verifiedAt' record={value} />
                            </span>
                        } else {
                            return <PlusBrandActivateButton record={record} />
                        }
                    }
                }
            />
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);

export const BrandShow = (props) => (
    <Show title={<BrandTitle />} {...props}>
        <SimpleShowLayout>
            <TextField label="Nev" source="name" />
            <TextField label='Cim' source='location' />
            <TextField label="Ország" source="country" />
            <FunctionField
                label="Befogado"
                source='adminVerified'
                render={
                    (record, source) => {
                        if (record[source]) {
                            return <span>
                                <ReferenceField source='checkedBy' record={record[source]} reference="backend-users" basePath="/" linkType='show'>
                                    <TextField source='name' />
                                </ReferenceField> -
                                <DateField source='verifiedAt' record={record[source]} locales="hu-HU" showTime />
                            </span>
                        } else {
                            return <PlusBrandActivateButton record={record} />
                        }
                    }
                }
            />

            <hr/>
            <h3>Számlázás</h3>
            <TextField label="Ország" source="billingAddress.countryCode"/>
            <TextField label="Irányítószám" source="billingAddress.zipCode"/>
            <TextField label="Város" source="billingAddress.city"/>
            <TextField label="Cím" source="billingAddress.address"/>
            <FunctionField label="Számlázási emailek" render={record => (
                record.billingEmails && record.billingEmails.length >0  &&
             <div style={styles.wrapper}>
                    {record.billingEmails.map(city => <Chip style={styles.chip}>{city}</Chip>)}
                </div>
                )
            }
            />
            <hr/>
            <FunctionField
            label="Számlázási nyelv"
            source="billingSettings.lang"
            render={(record) => record.billingSettings.lang === 'hu' ? 'HU' : 'AUT'}
            />

            <hr/>
            <h3>Kepviselet</h3>
            <TextField label="Nev" source="legalContact.name"/>
            <TextField label="Email" source="legalContact.email" type='email' />
            <TextField label="Tel" source="legalContact.phone" type='tel' />

            {/*<hr />*/}
            {/*<h3>Felhasználók</h3>*/}
            {/*<ReferenceArrayField label="Tags" reference="tags" source="tag_ids">*/}
            {/*    <SingleFieldList>*/}
            {/*        <ChipField source="name" />*/}
            {/*    </SingleFieldList>*/}
            {/*</ReferenceArrayField>*/}

            <hr/>
            <h3>Branding</h3>
            <TextField label='Fonts' source='branding.fonts' />
            <TextField label='Colors' source='branding.colors' />
            <hr />
            <BooleanField label="Chat engedélyezve" source="chatEnabled"/>
            <hr />
            <TextField label="Kategória" source="industryCategory" />
            <TextField label="Várható megrendelés" source="orderAmountCategory" />
            <h3>Drive Mappák</h3>
            <UrlFieldWithTarget label='Photon Dirve - kliens mappa' target='photon_drive_mappa' source='storage.url' />

            <FunctionField
                label='Kliens Dirve - photon mappa'
                render={
                    (record) => {
                        if (record.storage.oauth) {
                            return <UrlFieldWithTarget
                                record={record}
                                label='Kliens Dirve - photon mappa'
                                target='client_drive_photon_mappa'
                                source='storage.oauth.url'
                            />
                        }
                        return null;
                    }
                }
            />

            <hr />
            <SendEmailButton type={'users'}/>
        </SimpleShowLayout>
    </Show>
)
