import { CRUD_CREATE_FAILURE, CRUD_UPDATE_FAILURE } from "admin-on-rest";
import { stopSubmit } from 'redux-form';
import { put, takeEvery } from "redux-saga/effects";

const extractViolations = (payload) => {
  if (typeof payload !== 'object' || payload === null) {
    return {};
  }

  if (!('errors' in payload) || !payload.errors) {
    return {};
  }

  const errors = payload.errors.reduce((accumulated, errorElement) => {
    if ('paths' in errorElement) {
      accumulated[errorElement.paths[0]] = errorElement.title;
    } else {
      accumulated['_'].push(errorElement.title)
    }
    return accumulated;
  }, {'_': []});

  return errors
}

export default function* errorSagas() {
    yield takeEvery(CRUD_CREATE_FAILURE, crudFailue);
    yield takeEvery(CRUD_UPDATE_FAILURE, crudFailue);
}

export function* crudFailue(action) {
    let violations = []
    if (action !== null && typeof action === 'object' && 'payload' in action) {
      violations = extractViolations(action.payload);
    }

    yield put(stopSubmit('record-form', violations));
}

export { extractViolations }
