import {
    showNotification,
    refreshView,
} from 'admin-on-rest';
import {
    put,
    all,
    takeEvery,
} from "redux-saga/effects";

import {
    DEACTIVATE_PLUS_PACKAGE,
    ACTIVATE_PLUS_PACKAGE,
    DELETE_PLUS_PACKAGE,
    RESOLVE_MODIFY_PLUS_PACKAGE,
} from "../actions/plusPackageActions";


const packageSuccess = (text) => function* () {
    yield put(refreshView());
    yield put(showNotification(text));
};

function* packageFailure({payload}) {
    const errorMessage = (payload && payload.errorMessage) || 'Hiba történt a feldolgozás közben';

    yield put(refreshView());
    yield put(showNotification(errorMessage, 'error'));
}


export default function* plusPackageSagas() {
    yield all([
        takeEvery(`${DEACTIVATE_PLUS_PACKAGE}_FAILURE`, packageFailure),
        takeEvery(`${DEACTIVATE_PLUS_PACKAGE}_SUCCESS`, packageSuccess('Csomag kivezetve!')),

        takeEvery(`${ACTIVATE_PLUS_PACKAGE}_FAILURE`, packageFailure),
        takeEvery(`${ACTIVATE_PLUS_PACKAGE}_SUCCESS`, packageSuccess('Csomag elérhető!')),

        takeEvery(`${DELETE_PLUS_PACKAGE}_FAILURE`, packageFailure),
        takeEvery(`${DELETE_PLUS_PACKAGE}_SUCCESS`, packageSuccess('Csomag törölve!')),

        takeEvery(`${RESOLVE_MODIFY_PLUS_PACKAGE}_FAILURE`, packageFailure),
        takeEvery(`${RESOLVE_MODIFY_PLUS_PACKAGE}_SUCCESS`, packageSuccess('Csomag módosítás elfogadva!')),
    ]);
}
