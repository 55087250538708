import React from 'react';
import {
  Create,
  SimpleForm,
  ImageInput,
  ImageField,
  SelectInput,
  ReferenceInput,
} from 'admin-on-rest';
import { required } from 'admin-on-rest';
import { services } from '../../constants';

const CreativePhotoTitle = ({ record }) => {
  return <span>Fénykép {record ? `"${record.label}"` : ''}</span>;
};

export default (props) => {
  return <Create title={<CreativePhotoTitle />} resource="creative-photos" {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Kreatív"
        source="creative"
        allowEmpty
        reference="creatives"
        validate={ required }
        defaultValue={props.creativeId}
		perPage={1000}
		sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <SelectInput
        source="serviceType"
        choices={services}
        label="Típus"
      />
      <ImageInput
        label="Fénykép"
        source="photos"
        accept="image/*"
        maxSize={10*1024*1024}
        multiple
      >
        <ImageField source="src" title="" validate={ required }/>
      </ImageInput>
    </SimpleForm>
  </Create>
};
