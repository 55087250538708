import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FlatButton from 'material-ui/FlatButton';
import { creativeReject as creativeRejectAction } from '../../actions/juryActions';
import BlockIcon from 'material-ui/svg-icons/content/block';

class RejectButton extends Component {
  handleClick = () => {
    const { creativeReject, record } = this.props;
    const round = record.roundOneResult === null ? 'one' : 'two';
    creativeReject(record.id, round);
  }

  render() {
    const { record } = this.props;
    const canRender = record.roundOneResult !== 'rejected';
    const round = record.roundOneResult === null ? '1. kör' : '2. kör';

    return canRender
      ? <FlatButton
        primary
        label={`Elutasít (${round})`}
        onClick={this.handleClick}
        icon={<BlockIcon />}
        />
      : null;
  }
}

RejectButton.propTypes = {
  creativeReject: PropTypes.func,
  record: PropTypes.object,
};

export default connect(null, {
  creativeReject: creativeRejectAction,
})(RejectButton);
