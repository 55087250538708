import React, {Component} from 'react';
import FlatButton from 'material-ui/FlatButton';
import CancelIcon from 'material-ui/svg-icons/navigation/cancel';
import { cancelProject } from '../rest/fetch';
import Dialog from 'material-ui/Dialog';


export default class PlusProjectCancelButton extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        cancelModalOpen: false,
        reason: ''
      };
    }
  
     handleCancelButtonClick = async (e, requestId) =>{
      e && e.stopPropagation();
      e && e.preventDefault();
  
      try {
        await cancelProject({ projectId: requestId, reason: this.state.reason });
        window.location.reload();
      } catch (error) {
        console.error(error);
      }
    }

    render() {
      const { label } = this.props;
      return (
        <div>
          <FlatButton
            onClick={()=> this.setState({ cancelModalOpen: true })}
            primary
            icon={<CancelIcon />}
            style={{ overflow: 'inherit', color: 'green' }}
          />
  
          <Dialog
            title="Indokold meg a lemondást!"
            actions={this.actions}
            modal={false}
            open={!!this.state.cancelModalOpen}
            onRequestClose={this.handleCancelChange}
          >
            <div>
              <textarea 
                  style={{width:'100%', height:'100px', fontSize:'20px'}}
                onChange={(e)=>this.setState({ reason: e.target.value })}
                >
                    {this.state.reason}
              </textarea>
              <div style={{display:'flex', justifyContent:'end'}}>
                <FlatButton
                    label="Mégse"
                    onClick={()=> this.setState({ cancelModalOpen: false })}
                />
                <FlatButton
                    label="Lemondás"
                    disabled = {!this.state.reason.length > 0}
                    primary
                    onClick={(e)=> this.handleCancelButtonClick(e, this.props.record.id)}
                />
              </div>
            </div>
          </Dialog>
        </div>
      );
    }
  }
  


