import HttpError from "./HttpError"
import {saveAs} from 'file-saver'
import config from '../config';


const fetchJson = (url, options = {}) => {
    const requestHeaders =
        options.headers ||
        new Headers({
            Accept: "application/vnd.api+json"
        });
    // if (!(options && options.body && options.body instanceof FormData)) {
    //   requestHeaders.set("Content-Type", "application/vnd.api+json");
    // }
    if (options.user && options.user.authenticated && options.user.token) {
        requestHeaders.set("Authorization", options.user.token);
    }
    return fetch(url, {...options, headers: requestHeaders})
        .then(response =>
            response.text().then(text => ({
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text
            }))
        )
        .then(
            ({status, statusText, headers, body}) => {
                let json;
                try {
                    json = JSON.parse(body);
                } catch (e) {
                    // not json, no big deal
                }
                if (status < 200 || status >= 300) {
                    return Promise.reject(
                        new HttpError(
                            (json && json.message) || statusText,
                            status,
                            json
                        )
                    );
                }
                return {status, headers, body, json};
            }
        );
};

/**
 * @returns {Promise}
 */
export const plainHttpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: "application/json"});
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchJson(url, options);
}

/**
 * @returns {Promise}
 */
export const jsonApiHttpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: "application/vnd.api+json"});
    }
    options.headers.set("Content-Type", "application/vnd.api+json");
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchJson(url, options);
};

export const downloadAttachment = (url, filename = 'download.csv', options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: "application/csv"});
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    options.responseType = 'blob';

    return fetch(url, options)
        .then(response => response.blob())
        .then(blob => saveAs(blob, filename));
};

export const getCreativeCertificatesOfMonths = async (data, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: "application/json"});
    }
    const {month, year} = data;

    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);

    const queryParamsString = new URLSearchParams({month, year}).toString();

    const url = `${config.apiHost}/export/creative-tigs?${queryParamsString}`;

    const response = await fetch(url, options);
    if (!response.ok) {
        throw new Error("Request failed");
    }

    return await response.json();
};

export const downloadCreativeCertificatesOfMonth = async (data, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: "application/json"});
    }
    const {month, year} = data

    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);

    const queryParamsString = new URLSearchParams({month, year}).toString();
    const url = `${config.apiHost}/export/all-creative-tigs?${queryParamsString}`;

    const response = await fetch(url, options);
    const contentType = response.headers.get("content-type");

    if (!response.ok || contentType !== "application/zip") {
        throw new Error("Request failed");
    }

    const zipData = await response.arrayBuffer();

    const zipBlob = new Blob([zipData], {type: "application/zip"});

    const downloadUrl = URL.createObjectURL(zipBlob);

    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = `creative_certificates_${year}-${month}.zip`;
    link.click();

    URL.revokeObjectURL(downloadUrl);
};

export const updateProjectPrices = ({data, projectId}, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({
            Accept: 'application/csv',
            'Content-Type': 'application/json',
        });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);

    return fetch(`${config.apiHost}/project/${projectId}/price-update`, {
        method: 'PUT',
        body: JSON.stringify(data),
        ...options
    })
};

export const cancelProject = ({projectId, reason}, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({
            Accept: 'application/csv',
            'Content-Type': 'application/json',
        });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);

    return fetch(`${config.apiHost}/enterprise-projects/${projectId}/cancel`, {
        method: 'PUT',
        body: JSON.stringify({reason}),
        ...options
    })
};

function setAuthentication(options) {
    if (!options.headers) {
        options.headers = new Headers({Accept: "application/json"});
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
}

export const getAllCreativeEmailFromPackage = async ({packageId}, options = {}) => {
    setAuthentication(options);
    const url = `${config.apiHost}/admin/package/${packageId}/creative-emails`;
    const response = await fetch(url, options);
    return await response.json();
};

export const getAllEditorEmailFromPackage = async ({packageId}, options = {}) => {
    setAuthentication(options);
    const url = `${config.apiHost}/admin/package/${packageId}/editor-emails`;
    const response = await fetch(url, options);
    return await response.json();
};

export const getAllActiveUserEmailFromBrand = async ({clientId}, options = {}) => {
    setAuthentication(options);
    const url = `${config.apiHost}/admin/client/${clientId}/user-emails`;
    const response = await fetch(url, options);
    return await response.json();
};

export const getPackage = async ({packageId}, options = {}) => {
    setAuthentication(options);
    const url = `${config.apiHost}/admin/package/${packageId}`;
    const response = await fetch(url, options);
    return await response.json();
};

export const queryParameters = (data) => Object.keys(data)
    .map(key => [key, data[key]].map(encodeURIComponent).join("="))
    .join("&");
