import {
    showNotification,
    refreshView,
} from 'admin-on-rest';
import {
    put,
    all,
    takeEvery,
} from "redux-saga/effects";

import {
    CANCEL_PLUS_PROJECT,
    CANCEL_PLUS_PROJECT_BY_CREATIVE,
    DELETE_EXTRAS_PLUS_PROJECT,
    REMOVE_CREATIVE_FROM_PROJECT
} from "../actions/plusProjectActions";


const projectSuccess = (text) => function* () {
    yield put(refreshView());
    yield put(showNotification(text));
};

function* projectFailure({payload}) {
    const errorMessage = (payload && payload.errorMessage) || 'Hiba történt a feldolgozás közben';

    yield put(refreshView());
    yield put(showNotification(errorMessage, 'error'));
}


export default function* plusProjectSagas() {
    yield all([
        takeEvery(`${CANCEL_PLUS_PROJECT}_FAILURE`, projectFailure),
        takeEvery(`${CANCEL_PLUS_PROJECT}_SUCCESS`, projectSuccess('Projekt lemondva!')),
        takeEvery(`${CANCEL_PLUS_PROJECT_BY_CREATIVE}_FAILURE`, projectFailure),
        takeEvery(`${CANCEL_PLUS_PROJECT_BY_CREATIVE}_SUCCESS`, projectSuccess('Projekt lemondva!')),
        takeEvery(`${DELETE_EXTRAS_PLUS_PROJECT}_FAILURE`, projectFailure),
        takeEvery(`${DELETE_EXTRAS_PLUS_PROJECT}_SUCCESS`, projectSuccess('Projekt extrák törölve!')),
        takeEvery(`${REMOVE_CREATIVE_FROM_PROJECT}_FAILURE`, projectFailure),
        takeEvery(`${REMOVE_CREATIVE_FROM_PROJECT}_SUCCESS`, projectSuccess('Fotós eltávolítva!')),
    ]);
}
