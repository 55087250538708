export const CANCEL_PLUS_PROJECT = 'CANCEL_PLUS_PROJECT';
export const CANCEL_PLUS_PROJECT_BY_CREATIVE = 'CANCEL_PLUS_PROJECT_BY_CREATIVE';
export const DELETE_EXTRAS_PLUS_PROJECT = 'DELETE_EXTRAS_PLUS_PROJECT';
export const REMOVE_CREATIVE_FROM_PROJECT = 'REMOVE_CREATIVE_FROM_PROJECT'


export const cancelPlusProject = (id) => { 
    return {
    type: CANCEL_PLUS_PROJECT,
    payload: {id},
    meta: { resource: 'enterprise-projects', fetch: CANCEL_PLUS_PROJECT, cancelPrevious: false },
}};

export const cancelPlusProjectByCreative = (id) => { 
    return {
    type: CANCEL_PLUS_PROJECT_BY_CREATIVE,
    payload: {id},
    meta: { resource: 'enterprise-projects', fetch: CANCEL_PLUS_PROJECT_BY_CREATIVE, cancelPrevious: false },
}};

export const deleteExtrasPlusProject = (id) => ({
    type: DELETE_EXTRAS_PLUS_PROJECT,
    payload: {id},
    meta: { resource: 'enterprise-projects', fetch: DELETE_EXTRAS_PLUS_PROJECT, cancelPrevious: false },
});

export const removeCreativeFromProject = (id) => ({
    type: REMOVE_CREATIVE_FROM_PROJECT,
    payload: {id},
    meta: {fetch: REMOVE_CREATIVE_FROM_PROJECT, cancelPrevious: false },
});