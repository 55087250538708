import React from 'react';
import { List, Datagrid, TextField, Show, SimpleShowLayout, NumberField, ReferenceField } from 'admin-on-rest';
import { ShowButton } from 'admin-on-rest/lib/mui/button';

export const ReviewList = (props) => (
  <List
    title="Értékelések"
    resource="reviews"
    {...props}
    perPage={25}
    hasCreate={true}
    filter={{
      creative: props.creativeId
    }}
    sort={{ field: 'order', order: 'ASC' }}
  >
    <Datagrid>
      <ReferenceField label="Kliens" source="client_id" reference="clients" >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Megrendelés" source="inquiry_id" reference="inquiries" >
        <TextField source="title" />
      </ReferenceField>
      <NumberField label="Pontosság" source="punctuality" />
      <NumberField label="Kommunikáció" source="communication" />
      <NumberField label="Stílus" source="style" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const ReviewShow = (props) => (
  <Show
    title="Értékelés"
    resource="reviews"
    hasList={true}
    hasDelete={false}
    hasEdit={false}
    {...props}
  >
    <SimpleShowLayout>
      <ReferenceField label="Kliens" source="client_id" reference="clients" >
        <TextField source="name" />
      </ReferenceField>
      <TextField label="Pontosság" source="punctuality" />
      <TextField label="Kommunikáció" source="communication" />
      <TextField label="Stílus" source="style" />
      <TextField source="notes" />
    </SimpleShowLayout>
  </Show>
)

