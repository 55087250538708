import {
    showNotification,
    refreshView,
} from 'admin-on-rest';
import {
    put,
    all,
    takeEvery,
} from "redux-saga/effects";

import {
    VERIFY_PLUS_BRAND_INTEREST,
    ACCEPT_PLUS_BRAND_OWNER_CHANGE,
    REJECT_PLUS_BRAND_OWNER_CHANGE,
} from "../actions/plusBrandActions";


function* brandAcceptSuccess() {
    yield put(refreshView());
    yield put(showNotification('Sikeres feldolgozás'));
}

function* brandAcceptFailure(payload) {
    let {errorMessage} = payload;
    errorMessage = errorMessage || 'Hiba történt a feldolgozás közben';

    yield put(refreshView());
    yield put(showNotification(errorMessage, 'error'));
}


export default function* plusBrandSagas() {
    yield all([
        takeEvery(`${VERIFY_PLUS_BRAND_INTEREST}_SUCCESS`, brandAcceptSuccess),
        takeEvery(`${VERIFY_PLUS_BRAND_INTEREST}_FAILURE`, brandAcceptFailure),

        takeEvery(`${ACCEPT_PLUS_BRAND_OWNER_CHANGE}_SUCCESS`, brandAcceptSuccess),
        takeEvery(`${ACCEPT_PLUS_BRAND_OWNER_CHANGE}_FAILURE`, brandAcceptFailure),

        takeEvery(`${REJECT_PLUS_BRAND_OWNER_CHANGE}_SUCCESS`, brandAcceptSuccess),
        takeEvery(`${REJECT_PLUS_BRAND_OWNER_CHANGE}_FAILURE`, brandAcceptFailure),
    ]);
}
