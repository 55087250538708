import React, {Component} from 'react';
import {connect} from 'react-redux';
import {DeleteButton} from 'admin-on-rest';
import {Card, CardActions, CardMedia} from 'material-ui/Card';
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';

import config from '../../config';
import {creativePhotosSortingAction} from '../../actions/creativePhotos';
import {services} from "../../constants";


const serviceToName = serviceToResolve => {
    const service = services.filter(service => service.id === serviceToResolve).pop()

    if (!service) {
        return ''
    }

    return service.name
}

const cardStyle = {
    width: 320,
    minHeight: 240,
    margin: '0.5em',
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'move',
};

const PhotoCard = SortableElement(({id, record, basePath}) => (
    <li style={cardStyle}>
        <Card key={id} style={cardStyle}>
            <CardMedia
                overlay={
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <div style={{flexGrow: true}}>
                            <span style={{color: '#ECEFF1', textAlign: 'right'}}>
                                {serviceToName(record.serviceType)}
                            </span>
                        </div>
                    </div>
                }
                overlayContentStyle={{padding: '0.5em'}}
            >
                <img alt="Fénykép" src={`${config.imageHost}${record['imagePath']}`}/>
            </CardMedia>
            <CardActions style={{textAlign: 'right'}}>
                <DeleteButton resource="Photo" basePath={basePath} record={record}/>
            </CardActions>
        </Card>
    </li>
));

const SortablePhotos = SortableContainer(({items}) => {
    return (
        <ul>
            {
                items.map(
                    (item, index) => <PhotoCard
                        key={`card-${index}`}
                        index={index}
                        id={item.id}
                        record={item.record}
                        basePath={item.basePath}
                    />
                )
            }
        </ul>
    );
});

class CreativePhotoGrid extends Component {
    constructor(props) {
        super(props)
        const {ids, data, basePath} = this.props;
        this.props.creativePhotosSortingAction(null, []);
        this.state = {
            items: ids.map(id => ({
                id,
                record: data[id],
                basePath
            }))
        }
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        this.setState({
            items: arrayMove(this.state.items, oldIndex, newIndex),
        });
        this.props.creativePhotosSortingAction(this.props.creativeId, this.state.items.map(item => item.id));
    };

    render() {
        return <div style={{margin: '0em'}}>
            <SortablePhotos
                items={this.state.items}
                onSortEnd={this.onSortEnd}
                axis={"xy"}
            />
        </div>;
    }
}

CreativePhotoGrid.defaultProps = {
    data: {},
    ids: [],
};

const mapDispatchToProps = {
    creativePhotosSortingAction
};

export default connect(null, mapDispatchToProps)(CreativePhotoGrid);
