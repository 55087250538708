import { showNotification } from 'admin-on-rest';
import { put, all, call, select, takeEvery } from "redux-saga/effects";
import { plainHttpClient } from '../rest/fetch';
import config from '../config';

const selectCreativePhotosOrder = state => state.creativePhotos;

function* creativePhotosOrderSave() {
  const { creativeId, photos } = yield select(selectCreativePhotosOrder);
  try {
    const responsePromise = () => plainHttpClient(`${config.apiHost}/creatives/${creativeId}/photosOrder`, {
        headers: new Headers({
          Accept: "application/json",
          "Content-type": "application/json"
        }),
        method: 'POST',
        body: JSON.stringify({
          photos
        })
      })
      .then(response => {
        return response;
      })
      .catch(err => err)

    const response = yield call(responsePromise);
    if (response instanceof Error) {
      throw response;
    }

    yield put(showNotification('Sorrend elmentve'));
  } catch (e) {
    yield put(showNotification(e.message, 'error'));
  }
}

export default function* creativePhotosSagas() {
  yield all([
    takeEvery('PROVIDER_PHOTOS_ORDER_SAVE', creativePhotosOrderSave),
  ]);
}
