import React from 'react';

import {
  BooleanInput,
  Datagrid,
  DateField,
  DateInput,
  DisabledInput,
  Edit,
  Filter,
  FunctionField,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'admin-on-rest';
import {Field} from 'redux-form';

import PlusCancelButton from "../components/PlusCancelButton";
import PlusProjectDeleteExtrasButton from "../components/PlusProjectDeleteExtrasButton";
import PlusProjectPhotographer from "../components/PlusProjectPhotographer";
import PriceCorrection from '../components/PriceCorrection'
import Cancellation from '../components/Cancellation'
import PlusProjectDates from "../components/PlusProjectDates";
import RemovePhotographerFromProjectButton from "../components/RemovePhotographerFromProjectButton";
import ActionStars from 'material-ui/svg-icons/action/stars';
import moment from 'moment-timezone';


const statuses = [
  {id: 'canceled', name: 'canceled'},
  {id: 'created', name: 'created'},
  {id: 'deleted', name: 'deleted'},
  {id: 'delivered', name: 'delivered'},
  {id: 'finished', name: 'finished'},
  {id: 'retouching', name: 'photographer-editing'},
  {id: 'started', name: 'started'},
  {id: 'in-progress', name: 'in-progress'},
  {id: 'on-hold', name: 'on-hold'},
  {id: 'post-processing', name: 'editor-post-production'},
  {id: 'complain-in-progress', name: 'complain-in-progress'},
];

const PlusProjectFilter = (props) => (
  <Filter {...props}>
    <SelectInput label="Projekt fázis" source="status" choices={statuses}/>
    <TextInput label="Elnevezés vagy cég" source="q"/>
    <TextInput label="Fotós" source="c"/>
    <TextInput label="Város" source="l"/>
    <DateInput label="Fotózás időpontja (-tól)" source="fd"/>
    <DateInput label="Fotózás időpontja (-ig)" source="ud"/>
    <PerPageInput label='Sorok száma'/>
  </Filter>
);

function getBrandNameField() {
  return <FunctionField
    label='Cég'
    source="packageId"
    render={
      (record, source) => {
        if (record[source]) {
          return <ReferenceField
            record={record}
            source={source}
            reference="enterprise-packages"
            basePath='/'
          >
            <ReferenceField source='clientId_id' reference="enterprise-clients" basePath='/'>
              <TextField source="name"/>
            </ReferenceField>
          </ReferenceField>
        }
        return null;
      }
    }
  />;
}

const PerPageInput = ({input, ...rest}) => {
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    localStorage.setItem('perPage', selectedValue);
    window.location.reload();
  };

  const storedPerPage = localStorage.getItem('perPage') || '10';

  return (
    <select {...input} {...rest} value={storedPerPage} onChange={handleChange}>
      <option value="10">10</option>
      <option value="25">25</option>
      <option value="50">50</option>
      <option value="100">100</option>
    </select>
  );
};

const getLocalTime =(record, source)=>{
  return record['ianaCode']
  ? moment(record[source]).tz(record['ianaCode']).format('YYYY. MM. DD. HH:mm')
  : moment(record[source]).format('YYYY. MM. DD. HH:mm')
}

export const PlusProjectList = (props) => (
  <List
    title="Plus projektek"
    sort={{field: 'shootingTime', order: 'DESC'}}
    filters={<PlusProjectFilter/>}
    {...props}
    perPage={localStorage.getItem('perPage') || '10'}
  >
    <Datagrid>
      <TextField label='Id' source='id'/>
      <TextField label="Elnevezes" source="projectName"/>
      {getBrandNameField()}
      <DateField label="Létrehozva" source="createdAt" locales="hu-HU" showTime/>
      <FunctionField
        label="Fotózás ideje (helyi)"
        source="shootingTime"
        render={(record, source) => getLocalTime(record, source)}
      />
      <DateField label="Fotózás ideje (HQ)" source="shootingTime" locales="hu-HU" showTime/>
      <SelectField label="Projekt fázis" source="status" choices={statuses}/>
      <FunctionField
        label="Cím"
        source="lacationObj"
        render={(record) => record.locationObj ? record.locationObj.city : record.location}
      />
      <TextField label='Várható képsz.' source='expectedPhotoNumber'/>
      <TextField label='Kreatív díja' source='calculatedCreativePrice'/>
      <FunctionField
        label="Kreatív"
        source="photographer"
        render={
          (record, source) => <PlusProjectPhotographer record={record} source={source}/>
        }
      />
      <FunctionField
        label="Editor"
        source="editor"
        render={
          (record, source) => {
            if (record[source]) {
              return <a href={`/#/editors/${record.editor}/show`}>
                {record.editorName}
              </a>
            }
            return <p> --- </p>;
          }
        }
      />
      <TextField label="Értékelés" source="photographerReview.rating"/>
      <FunctionField
        label="Lemondás"
        source="status"
        render={
          (record, source) => {
            if (
              record[source] !== "canceled"
              && record[source] !== "delivered"
              && record[source] !== "deleted"
              && record[source] !== "finished"
              && record[source] !== "finished"
            ) {
              return <PlusCancelButton record={record}/>;
            } else {
              return null;
            }
          }
        }
      />
      <FunctionField
        label="Megjelölve"
        source="adminFlag"
        render={(record, source) => record[source] === true ? <ActionStars color='#ffb300'/> : null}
      />
      <ShowButton/>
    </Datagrid>
  </List>
);

const DeleteExtras = (props) => <FunctionField
  {...props}
  label=""
  source='status'
  render={
    (record, source) => {
      return <React.Fragment>
        {
          record[source] === 'delivered'
          && record.extras && record.extras.some(x => x !== 0)
          && <div>
            <PlusProjectDeleteExtrasButton record={record}/>
          </div>
        }
      </React.Fragment>;
    }
  }
/>


export const PlusProjectShow = (props) => (
  <Show {...props} title='Plus projekt'>
    <SimpleShowLayout>
      <TextField label="Id" source="id"/>
      <FunctionField
        label="Admin által megjelölt"
        source='adminFlag'
        render={(record, source) => record[source] ? 'IGEN' : 'NEM'}
      />
      <TextField label="Egyéb információ" source="adminComment"/>
      <TextField label="Status" source="status"/>
      <FunctionField
        label="Lemondás az ügyfél nevében"
        source="status"
        render={
          (record, source) => {
            if (
              record[source] !== "canceled"
              && record[source] !== "deleted"
              && record[source] !== "finished"
            ) {
              return <PlusCancelButton record={record}/>;
            } else {
              return <span>{record[source]}</span>;
            }
          }
        }
      />
      <TextField label="Projekt név" source="projectName"/>

      <FunctionField
        label="Időpont (helyi)"
        source="shootingTime"
        render={(record, source) => getLocalTime(record, source)}
      />
      <DateField label='Időpont (HQ)' source='shootingTime' locales="hu-HU" showTime/>


      <hr/>
      <TextField source="packageId"/>
      <ReferenceField label="Csomag" source="packageId" reference="enterprise-packages">
        <TextField source="name"/>
      </ReferenceField>

      <hr/>
      <ReferenceField label="Létrehozó" source="createdBy_id" reference="enterprise-users">
        <TextField source="name"/>
      </ReferenceField>
      {getBrandNameField()}

      <FunctionField
        label="Leadás dátuma (helyi)"
        source="deliveryDeadline"
        render={(record, source) => getLocalTime(record, source)}
      />
      <DateField label='Leadás dátuma (HQ)' source='deliveryDeadline' locales="hu-HU" showTime/>

      {/*TODO refFiles show*/}
      {/*<ImageField source="refFiles" src="url" title="desc" />*/}

      <hr/>
      <h3>Helyszín</h3>
      <TextField label='' source='location'/>
      <h4>Kapcsolattartó</h4>
      <TextField label='Név' source="contact.name"/>
      <TextField label='Email' source='contact.email'/>
      <TextField label='Tel.' source='contact.phone'/>

      <hr/>
      <h3>Fotós</h3>
      <FunctionField
        label="Név"
        source="photographer_id"
        render={
          (record, source) => {
            return <PlusProjectPhotographer record={record} source={source}/>;
          }
        }
      />
      <RemovePhotographerFromProjectButton/>

      <FunctionField
        label="Fotós leadás dátuma (helyi)"
        source="photographerDeliveryDeadline"
        render={(record, source) => getLocalTime(record, source)}
      />
      <DateField label='Fotós leadás dátuma (HQ)' source='photographerDeliveryDeadline' locales="hu-HU" showTime/>

      <TextField source='creativeMessage' label='Fotós megjegyzés'/>

      <TextField source='photographerReview.rating' label='Értékelés'/>
      <TextField source='photographerReview.message' label='Szöveges értékelés'/>

      <h3>Editor</h3>
      <FunctionField
        label="Editor"
        source="editor_id"
        render={
          (record, source) => {
            if (record[source]) {
              return <a href={`/#/editors/${record.editor_id}/show`}>
                {record.editorName}
              </a>
            }
            return <p> --- </p>;
          }
        }
      />
      <TextField label='Editor által feltöltött képek száma' source='uploadedPhotosByEditorNumber'/>

      <hr/>
      <h3>Megjegyzés</h3>
      <TextField label='Megjegyzés' source='comment'/>

      <hr/>
      <h3>Extrák</h3>
      <FunctionField
        label="Extrák"
        source="extras"
        render={
          (record, _) => {
            if (record.extras) {
              return <ul>
                {
                  record.extras.map(
                    (x, i) => {
                      return (<li key={i}>
                        {x.name}: {x.quantity} {x.unit} ({x.amount} {x.currency})
                      </li>)
                    })
                }
              </ul>
            }
            return null;
          }
        }
      />

      <FunctionField
        source="dynamicExtraPicsNum"
        label='Extra képek dinamikus árazással'
        render={
          (record, source) => record[source]
            ? <p>{record[source] + ' pc' + ' (' + record['dynamicExtraPicPrice'] + ')'}</p>
            : <p> --- </p>
        }
      />

      <hr/>
      <h4>AI képszerkesztés</h4>
      <TextField source="editedPhotosNumber" label="Szerkesztett képek száma"/>

      <ReferenceField label="Egységár" source="packageId" reference="enterprise-packages">
        <FunctionField
          render={
            (record) => record.editingPrice
              && `${record.editingPrice.amount} ${record.editingPrice.currency}`
          }
        />
      </ReferenceField>

      <DeleteExtras/>
      <hr/>

      <h3>Lemondás</h3>
      <Cancellation/>

      <hr/>
      <h3>Események</h3>
      <FunctionField
        label=""
        source='events'
        render={
          (record, source) => {
            if (Array.isArray(record[source])) {
              return record[source].map(
                (eventRec) => {
                  return (
                    <div key={eventRec.time}>
                      <b>{eventRec.event}</b>: <DateField record={eventRec} source='time' locales="hu-HU" showTime/>
                      {
                        eventRec.event === 'cancelByCreative'
                        && <ul>
                          <li>
                            <ReferenceField
                              reference={eventRec.details.cancelledBy ? eventRec.details.cancelledBy.type : 'creatives'}
                              record={eventRec.details}
                              source={eventRec.details.cancelledBy ? 'cancelledBy.id' : 'creativeId'}
                              linkType='show'
                              basePath='/'
                            >
                              <TextField source="name"/>
                            </ReferenceField>
                          </li>
                          <li>oka: {eventRec.details.reason}</li>
                          <li>típusa: {eventRec.details.creativeFeeType}</li>
                          <li>kötbér: {eventRec.details.creativeFee} {eventRec.details.creativeFeeCurrency}</li>
                        </ul>
                      }
                      {
                        eventRec.event === 'creativeLateFee'
                        && eventRec.details
                        && <ul>
                          <li>
                            <ReferenceField
                              reference='creatives'
                              record={eventRec.details}
                              source='creativeId'
                              linkType='show'
                              basePath='/'
                            >
                              <TextField source="name"/>
                            </ReferenceField>
                          </li>
                          <li>késés mértéke: {eventRec.details.lateInHours} óra</li>
                          <li>büntetés: {eventRec.details.creativeFeePercent}</li>
                          <li>kötbér: {eventRec.details.creativeFee} {eventRec.details.creativeFeeCurrency}</li>
                        </ul>
                      }
                      {
                        eventRec.event === 'canceled'
                        && eventRec.details
                        && <ul>
                          <li>oka: {eventRec.details.reason}</li>
                          <li>típusa: {eventRec.details.clientFeeType}</li>
                          <li>
                            ügyfél díj:&nbsp;
                            {eventRec.details.clientFee} {eventRec.details.clientFeeCurrency}
                          </li>
                          {
                            eventRec.details.cancelledBy
                            && eventRec.details.cancelledBy.type
                            && <li>
                              <ReferenceField
                                reference={eventRec.details.cancelledBy.type}
                                record={eventRec.details}
                                source={'cancelledBy.id'}
                                linkType='show'
                                basePath='/'
                              >
                                <TextField source="name"/>
                              </ReferenceField>
                            </li>
                          }
                        </ul>
                      }
                      {
                        eventRec.event === 'priceCorrection'
                        && <ul>
                          <li>oka: {eventRec.details.reason}</li>
                          <li>típusa: {eventRec.details.type}</li>
                          <li>ár
                            változás: {eventRec.details.amount} {eventRec.details.currency}</li>
                        </ul>
                      }
                      {
                        eventRec.event === 'projectDataModification'
                        && <ul>
                          <li> módosította:
                            <ReferenceField
                              reference="backend-users"
                              record={eventRec.details}
                              source='adminMadeChangesId'
                              linkType='show'
                              basePath='/'
                            >
                              <TextField source="name"/>
                            </ReferenceField>
                          </li>
                          {
                            eventRec.details.newValues
                            && Object.entries(eventRec.details.newValues).map(
                              (mod, index) => {
                                if (mod[0] === 'extras') {
                                  return Object.entries(mod[1])
                                    .map(
                                      (e, subIndex) => <li key={`${index}-${subIndex}`}>{`${e[0]}: ${e[1]}`}</li>
                                    );
                                }
                                return (
                                  <li key={index}>{`${mod[0]}: ${mod[1]}`}</li>
                                );
                              }
                              )
                          }
                        </ul>
                      }
                    </div>
                  )
                })
            }
            return null;
          }
        }
      />

      <hr/>
      <h3>Várható képszám</h3>
      <FunctionField
        source='expectedPhotoNumber'
        label="Várható képek száma"
        render={
          (record, source) => {
            if (record[source]) {
              return record[source]
            }
            return '--';
          }
        }
      />

      <hr/>
      <h3>Leadott képek</h3>
      <FunctionField
        source='uploadedFiles'
        label="Feltöltött képek száma:"
        render={
          (record, source) => {
            if (record['uploadedPhotosNumber']) {
              return <p>{`${record['uploadedPhotosNumber']} db`}</p>
            } else if (Array.isArray(record[source])) {
              return `${record[source].length} db`
            } else {
              return null;
            }
          }
        }
      />


      <FunctionField
        source='clientPrice'
        label="Projekt díj"
        render={
          (record, source) => {
            if (record.state !== 'canceled') {
              return <p> {`${record[source]}  ${record['price'].currency}`}  </p>
            }
          }
        }
      />

      <FunctionField
        source='creativePrice'
        label="Fotós ár"
        render={
          (record, source) => {
            if (record.photographer_id) {
              return <p> {`${record[source]}  ${record['price'].currency}`}  </p>
            } else {
              return <p>---</p>
            }
          }
        }
      />

      <FunctionField
        source='editorPrice'
        label="Képszerkesztő díja"
        render={
          (record, source) => {
            if (record[source]) {
              return <p> {`${record[source]}  ${record['price'].currency}`}  </p>
            } else {
              return <p>---</p>
            }
          }
        }
      />

      <FunctionField
        source='clientCancellationFee'
        label="Ügyfél általi lemondási díj"
        render={
          (record, source) => {
            if (record.events.find(e => e.event === 'canceled')) {
              return <p> {`${record[source]}  ${record['price'].currency}`}  </p>
            } else {
              return <p>---</p>
            }
          }
        }
      />

      <FunctionField
        source='creativeFee'
        label="Fotós kötbér"
        render={
          (record) => {
            if (record.events.find(e => e.event === 'cancelByCreative')) {
              const creativeFee = record.events.filter(e => e.event === 'cancelByCreative')
              if (creativeFee) {
                return creativeFee.map(e => {
                  if (e.details.creativeFee) {
                    return <p> {e.details.creativeId}: {e.details.creativeFee} {e.details.creativeFeeCurrency} </p>
                  }
                })
              } else {
                return <p>---</p>
              }
            }
          }
        }
      />
      <hr/>
      <FunctionField
        source='creativeLateFee'
        label="Fotós késői leadás kötbér"
        render={
          (record) => {
            const creativeLateFees = record.events.filter(({event}) => event === 'creativeLateFee')
            if (creativeLateFees && creativeLateFees.length) {
              return creativeLateFees.map(
                ({details, _id}) => {
                  if (details.creativeFee) {
                    return <p key={_id}>
                      {details.creativeFee} {details.creativeFeeCurrency}&nbsp;
                      (<ReferenceField
                        reference='creatives'
                        record={details}
                        source='creativeId'
                        linkType='show'
                        basePath='/'
                      >
                        <TextField source="name"/>
                      </ReferenceField>)
                    </p>
                  }
                }
              )
            } else {
              return <p>---</p>
            }
          }
        }
      />
      <hr/>

      <h3>Feltöltött útmutatók (fileok)</h3>
      <FunctionField
        label=""
        source='refFiles'
        render={
          (record, source) => {
            if (Array.isArray(record[source])) {
              return (
                <ul>
                  {record[source].map((file, index) => (
                    <li key={index}>
                      <a href={atob(file.url)} target="_blank" rel="noreferrer">
                        {file.name}
                      </a>
                    </li>
                  ))}
                </ul>
              );
            }
            return null;
          }
        }
      />

      <FunctionField
        label="Drive mappa"
        source='storage'
        render={
          (record, source) => {
            return (
              <a href={record.storage.url} target="_blank" rel="noopener noreferrer">
                {record.storage.url}
              </a>
            );
          }
        }
      />

    </SimpleShowLayout>
  </Show>
);

const ArrayItemObjectItemInput = ({source, record = {}, label, ...rest}) => {
  const value = record[source];
  return <React.Fragment>
    <h3>{label || source}</h3>
    {
      value && value.length > 0 &&
      value.map(
        (item, idx) => <Field
          key={idx}
          component={NumberInput}
          label={`${item.name} (${item.unit})`}
          defaultValue={item.quantity}
          name={`${source}.${idx}.quantity`}
        />
      )
    }
  </React.Fragment>;
}

export const PlusProjectEdit = (props) => (
  <Edit {...props} title='Plus projekt'>
    <SimpleForm>
      <DisabledInput label="Id" source="id"/>
      <BooleanInput label="Admin által megjelölve" source="adminFlag"/>
      <TextInput source='adminComment' label='Egyéb megjegyzés' elStyle={{width: '100%'}}/>

      <SelectInput label="Projekt fázis" source="status" choices={statuses}/>
      <DisabledInput label="Projekt név" source="projectName"/>

      <PlusProjectDates/>

      <hr/>
      <DisabledInput label="Csomag" source="packageId"/>
      {/*<ReferenceInput label="Csomag" source="packageId" reference="enterprise-packages">*/}
      {/*    <SelectInput optionText="name" />*/}
      {/*</ReferenceInput>*/}

      <hr/>
      <h3>Várható képszám</h3>
      <NumberInput
        source='expectedPhotoNumber'
        label="Várható képek száma"
      />

      <hr/>
      <h3>Helyszín</h3>
      <DisabledInput label='Egyben' source='location'/>
      <DisabledInput label='Orszag' source='locationObj.country'/>
      <DisabledInput label='Varos' source='locationObj.city'/>
      <DisabledInput label='Iranyito szam' source='locationObj.zipCode'/>
      <DisabledInput label='Utca' source='locationObj.address'/>

      <h4>Kapcsolattartó</h4>
      <DisabledInput label='Név' source="contact.name"/>
      <DisabledInput label='Email' source='contact.email' options={{type: 'email'}}/>
      <DisabledInput label='Tel.' source='contact.phone' options={{type: 'tel'}}/>

      <hr/>
      <h3>Fotós</h3>
      <DisabledInput label='Fotós' source="photographer"/>
      {/*<ReferenceInput label='Fotós' source="photographer" reference="creatives">*/}
      {/*    <SelectInput optionText="name" />*/}
      {/*</ReferenceInput>*/}

      <hr/>
      <h3>Szerkesztő</h3>
      <ReferenceInput
        label='Szerkesztő'
        source="editor_id"
        reference="active-editors"
        perPage={1000}
        InputProps={{maxRows: 8}}
      >
        <SelectInput optionText="name"/>
      </ReferenceInput>

      <hr/>
      <h3>Megjegyzés</h3>
      <DisabledInput label='Megjegyzés' source='comment'/>

      <hr/>
      <h3>Leadott képek</h3>
      <NumberInput label="Feltöltött képek száma:" source='uploadedPhotosNumber'/>

      <h4>Editor leadott képek</h4>
      <NumberInput source="uploadedPhotosByEditorNumber" label="Szerkesztett képek száma"/>

      <h4>AI képszerkesztés</h4>
      <NumberInput source="editedPhotosNumber" label="AI Szerkesztett képek száma"/>

      <h3>Extrák</h3>
      <NumberInput label='Extra képek dinamikus árazással' source='dynamicExtraPicsNum'/>

      <ArrayItemObjectItemInput label="Extrák" source="extras"/>

      <hr/>
      <PriceCorrection />

    </SimpleForm>
  </Edit>
);
