import React from 'react';
import {
  CheckboxGroupInput,
  Create,
  Datagrid,
  DeleteButton,
  DisabledInput,
  Edit,
  EditButton,
  EmailField,
  List,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput
} from 'admin-on-rest';
import FunctionField from "admin-on-rest/lib/mui/field/FunctionField";

export const BackendUserList = (props) => (
  <List title="Backend felhasználók " {...props}>
    <Datagrid>
      <TextField source="id"/>
      <TextField source="name"/>
      <TextField source="email"/>
      <EditButton/>
      <DeleteButton/>
    </Datagrid>
  </List>
);

export const BackendUserCreate = (props) => {
  return <Create title="Backend felhasználó létrehozás" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Név" source="name"/>
      <TextInput source="email" type="email"/>
      <TextInput label="Jelszó" source="password" type="password"/>
      <CheckboxGroupInput label="Jogosultságok" source="roles" choices={[
        {id: 'admin', name: 'Admin'},
        {id: 'jury', name: 'Zsűri'},
      ]}/>
    </SimpleForm>
  </Create>;
}

const BackendUserTitle = ({record}) => {
  return <span>Felhasználó {record ? `"${record.name}"` : ''}</span>;
};

export const BackendUserEdit = (props) => (
  <Edit title={<BackendUserTitle/>} {...props} >
    <SimpleForm redirect="list">
      <DisabledInput source="id"/>
      <TextInput label="Név" source="name"/>
      <TextInput source="email" type="email"/>
      <TextInput label="Jelszó" source="password" type="password"/>
      <CheckboxGroupInput label="Jogosultságok" source="roles" choices={[
        {id: 'admin', name: 'Admin'},
        {id: 'jury', name: 'Zsűri'},
      ]}/>
    </SimpleForm>
  </Edit>
);

export const BackendUserShow = (props) => (
  <Show title={<BackendUserTitle/>} {...props} >
    <SimpleShowLayout>
      <TextField label="Név" source="name"/>
      <EmailField label="Email" source="email"/>
      <TextField label="Telefon" source="phone"/>
      <FunctionField
        label="Szerepek"
        source="roles"
        render={
          (record, source) => <span>{record[source].reduce((a, r) => a + r, '')}</span>
        }
      />
    </SimpleShowLayout>
  </Show>
);

