import React from 'react';

import PropTypes from 'prop-types';
import shouldUpdate from 'recompose/shouldUpdate';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import FlatButton from 'material-ui/FlatButton';
import ActivateIcon from 'material-ui/svg-icons/content/add-box';

import {acceptOwnerChange} from "../actions/plusBrandActions";


const handle = (e, requestId, acceptOwnerChange) => {
    e && e.stopPropagation();
    e && e.preventDefault();

    acceptOwnerChange(requestId);
}

const PlusPackageActivateButton = (
    {
        label = 'Aktívál',
        record = {},
        acceptOwnerChange,
    }
) => (
    <FlatButton
        onClick={(e) => handle(e, record.id, acceptOwnerChange)}
        primary
        label={label}
        icon={<ActivateIcon />}
        style={{ overflow: 'inherit', color: 'green' }}
    />
);

PlusPackageActivateButton.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    acceptOwnerChange: PropTypes.func,
};

const enhance = compose(
    shouldUpdate(
        (props, nextProps) =>
            (props.record &&
                nextProps.record &&
                props.record.id !== nextProps.record.id) ||
            props.basePath !== nextProps.basePath ||
            (props.record == null && nextProps.record != null)
    ),
    connect(null, {acceptOwnerChange})
);

export default enhance(PlusPackageActivateButton);
