import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import {Field} from 'redux-form';

import {plainHttpClient} from "../rest/fetch";
import config from "../config";
import moment from 'moment-timezone';


const getTime = (time, ianaCode, inputFormat, toUtc, ) => {
  let result;


  if (inputFormat) {
    result = moment.tz(time, inputFormat, ianaCode || 'Europe/Budapest');
  } else {
    result = moment.tz(time, ianaCode || 'Europe/Budapest');
  }

  if (toUtc) {
    result = result.utc();
  }

  return result;
}

class DateInput extends React.Component {

  constructor(props) {
    super(props);

    const {
      input: {value, onChange},
      newDate,
      meta: {visited},
      ianaCode
    } = this.props;

    const newValue = getTime(visited && value || newDate || value, ianaCode).format('YYYY-MM-DD HH:mm');
    const oldValue = getTime(value, ianaCode).format('YYYY-MM-DD HH:mm');

    this.state = {
      error: null,
      value: getTime(visited && value || newDate || value, ianaCode).format('YYYY-MM-DD HH:mm'),
    };

    if (newValue !== oldValue) {
      onChange(getTime(visited && value || newDate || value, ianaCode, null, true).format());
    }
  }

  convertDateAndSave = (e) => {
    const cb = this.props.callback;
    const {
      input: {onChange: save},
      ianaCode
    } = this.props;

    const t = getTime(e.target.value,ianaCode,  'YYYY-MM-DD HH:mm');
    if (t.isValid()) {
      this.setState({error: null});
      save(t.utc().format());
      cb && cb(t.format());
    } else {
      this.setState({error: 'Hibás dátum vagy formátum!'});
      e.preventDefault();
    }
  }

  render() {
    const {
      input: {
        value,
        onChange,
        onFocus,
      },
      label,
      ianaCode
    } = this.props;
    const {
      error,
      value: inputDate,
    } = this.state;

    return (
      <Fragment>
        <div title='Használd az 2023-10-12 09:30, időpont formátumot!'>
          <label>{label}: </label>
          <input
            onFocus={onFocus}
            onChange={(e) => this.setState({value: e.target.value})}
            onBlur={(e) => this.convertDateAndSave(e, onChange, ianaCode)}
            value={inputDate}
            required
          />
        </div>
        <div><small>UTC time: {value}</small></div>
        {
          error
          && <h6 style={{color: 'red'}}>
            {error}
            <br/>
            Használd az 2023-10-12 09:30, időpont formátumot!
          </h6>
        }
      </Fragment>
    )
  }
}

class PlusProjectDates extends Component {
  constructor(props) {
    super(props);

    const {
      projectDrivers,
      ianaCode,
    } = this.props.record;

    this.state = {
      loading: true,
      projectDrivers,
      ianaCode
    }

    this.loadPackage();
  }

  loadPackage = () => {
    plainHttpClient(
      `${config.apiHost}/enterprise-packages/${this.props.record.packageId}`)
      .then(
        (response) => {
          const data = response.json.data.attributes;

          this.setState({
            deliveryTime: data.deliveryTime,
            photographerDeliveryTime: data.photographerDeliveryTime,
          });
        }
      ).catch(console.error)
      .finally(() => this.setState({loading: false}));
  }

  adjustTimes = (shootingTime) => {
    const {
      deliveryTime,
      photographerDeliveryTime,
      projectDrivers,
      ianaCode
    } = this.state;

    const photographerDeliveryDeadline = moment(shootingTime);
    const deliveryDeadline = moment(shootingTime);

    deliveryDeadline.add(deliveryTime.amount, deliveryTime.unit);
    photographerDeliveryDeadline.add(photographerDeliveryTime.amount, photographerDeliveryTime.unit);

    // if delivery time point to weekend then we step to the next week day
    if (projectDrivers.includes('delivery_on_workday')) {
      if (deliveryDeadline.isoWeekday() > 5) {
        deliveryDeadline.add(1, 'w').isoWeekday(1);
      }
    }

    // if desired time of a day has been set we should deliver that time
    if (projectDrivers.includes('delivery_at_time')) {
      const budapestOffset = moment.tz.zone('Europe/Budapest').offset(moment())
      const ianaOffset = moment.tz.zone(ianaCode).offset(moment())
      const utcOffsetDifference = budapestOffset - ianaOffset
      if (deliveryTime.atHour >= 0 && deliveryTime.atHour <= 23) {
        const adjustedHour = deliveryTime.atHour - Math.floor(utcOffsetDifference / 60);
        deliveryDeadline.hour(adjustedHour).minutes(0).seconds(0).milliseconds(0);
      }
    
      if (photographerDeliveryTime.atHour >= 0 && photographerDeliveryTime.atHour <= 23) {
        const adjustedHour = photographerDeliveryTime.atHour - Math.floor(utcOffsetDifference / 60);
        photographerDeliveryDeadline.hour(adjustedHour).minutes(0).seconds(0).milliseconds(0);
      }
    }

    this.setState({
      newPhotographerDeliveryDeadline: photographerDeliveryDeadline.format(),
      newDeliveryDeadline: deliveryDeadline.format(),
    });
  }

  render() {
    const {
      newPhotographerDeliveryDeadline,
      newDeliveryDeadline,
      loading,
      ianaCode
    } = this.state;

    return <Fragment>
      <h3>Dátumok</h3>

      {
        loading
        && <p>Loading ...</p>
      }
      {
        !loading
        && <Fragment>
          <Field
            label="Fényképezés időpont (local)"
            name="shootingTime"
            component={(props) => <DateInput {...props} callback={this.adjustTimes} ianaCode={ianaCode}/>}
          />
          <br/>
          <Field
            label="Fotós leadás időpont (local)"
            name="photographerDeliveryDeadline"
            component={(props) => <DateInput {...props} newDate={newPhotographerDeliveryDeadline} ianaCode={ianaCode}/>}
          />
          <br/>
          <Field
            label="Leadás időpont (local)"
            name="deliveryDeadline"
            component={(props) => <DateInput {...props} newDate={newDeliveryDeadline} ianaCode={ianaCode}/>}
          />
          <br/>
        </Fragment>
      }
    </Fragment>
  }
}

PlusProjectDates.propTypes = {
  record: PropTypes.object,
};

PlusProjectDates.defaultProps = {
  addField: true, // require a <Field> decoration
}

export default PlusProjectDates;
