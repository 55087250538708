import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FlatButton from 'material-ui/FlatButton';
import { creativeAccept as creativeAcceptAction } from '../../actions/juryActions';
import CheckIcon from 'material-ui/svg-icons/navigation/check';

class AcceptButton extends Component {
  handleClick = () => {
    const { creativeAccept, record } = this.props;
    const round = record.roundOneResult === null ? 'one' : 'two';
    creativeAccept(record.id, round);
  }

  render() {
    const { record } = this.props;
    const canRender = record.roundOneResult !== 'rejected';
    const round = record.roundOneResult === null ? '1. kör' : '2. kör';

    return canRender
      ? <FlatButton
        primary
        label={`Elfogad (${round})`}
        onClick={this.handleClick}
        icon={<CheckIcon />}
      />
      : null;
  }
}

AcceptButton.propTypes = {
  creativeAccept: PropTypes.func,
  record: PropTypes.object,
};

export default connect(null, {
  creativeAccept: creativeAcceptAction,
})(AcceptButton);
