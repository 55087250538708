
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    SelectField,
    DeleteButton,
    FunctionField,
} from 'admin-on-rest';
import React from "react";
import {plusInviteStatuses, userRoles} from "../constants";
import config from "../config";


export const PlusInvitesList = (props) => (
    <List title="Plus meghívottak" {...props}>
        <Datagrid>
            <SelectField label="Status" source="status" choices={plusInviteStatuses}/>
            <TextField label="Email" source="email"/>
            <TextField label="Név" source="name"/>
            <TextField label="Telefon" source="phone"/>

            <ReferenceField label="Cég" source="brand_id" reference="enterprise-clients">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Meghívó" source="invitedBy_id" reference="enterprise-users">
                <TextField source="name" />
            </ReferenceField>

            <FunctionField
                label="Meghívó link"
                render={record => <a href={`${config.plusHost}/registration/${record.regToken}`} target='_blank'>Meghívó link</a>}
            />

            <SelectField label='Jogosultság' source="role" choices={userRoles} />

            <DateField label="Meghívás dátuma" source="createdAt" locales="hu-HU" showTime />

            {/*<ShowButton />*/}
            {/*<EditButton />*/}
            <DeleteButton />
        </Datagrid>
    </List>
);
