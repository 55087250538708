import React from 'react';

import {
    Datagrid,
    NumberInput,
    NumberField,
    BooleanField,
    BooleanInput,
    CheckboxGroupInput,
    Create,
    DeleteButton,
    Edit,
    EditButton,
    EmailField,
    Filter,
    FunctionField,
    List,
    ListButton,
    LongTextInput,
    RefreshButton,
    SelectArrayInput,
    SelectInput,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    WithPermission,
} from 'admin-on-rest';
import {
    EmbeddedArrayField,
    EmbeddedArrayInput,
} from 'aor-embedded-array';

import Chip from 'material-ui/Chip';
import BlockIcon from 'material-ui/svg-icons/content/block';
import CheckIcon from 'material-ui/svg-icons/navigation/check';
import {CardActions} from 'material-ui/Card';

import PhotosButton from '../components/PhotosButton';
import AcceptButton from '../components/AcceptButton';
import RejectButton from '../components/RejectButton';
import ReviewsButton from '../components/ReviewsButton';
import UrlFieldWithTarget from '../components/UrlFieldWithTarget';
import {languages, services, filteredServices} from '../constants';

import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn,
} from 'material-ui/Table';


const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const rounds = [
    {id: 'unrated-one', name: 'Első értékelésre vár'},
    {id: 'accepted-one', name: 'Első fordulóban elfogadva'},
    {id: 'rejected-one', name: 'Első fordulóban elutasítva'},
    {id: 'unrated-two', name: 'Második értékelésre vár'},
    {id: 'accepted-two', name: 'Második fordulóban elfogadva'},
    {id: 'rejected-two', name: 'Második fordulóban elutasítva'},
]

const serviceMap = services.reduce((accumulated, current) => {
    accumulated[current.id] = current.name

    return accumulated
}, {})

const styles = {
    chip: {
        margin: 4,
    },
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
};

const CreativeFilter = (props) => (
    <Filter {...props}>
        <SelectArrayInput label="Szolgáltatások" source="services" choices={services}/>
        <SelectInput label="Forduló" source="round" choices={rounds} defaultValue="accepted-two"/>
        <TextInput label="Név vagy email" source="q"/>
        <TextInput label="Városok" source="w"/>
    </Filter>
);

const ServicesField = ({source, record}) => (
    <div style={{display: 'flex', flexWrap: 'wrap'}}>
        {
            record['services'].map(
                item => <Chip key={item} style={{margin: 4}}>
                    {services.filter(service => item === service.id)[0].name}
                </Chip>
            )
        }
    </div>
)

export const CreativeList = ({...props}) => (
    <List title="Kreatívok" filters={<CreativeFilter/>} {...props}>
        {permissions =>
            <Datagrid>
                <TextField label="Neve" source="name"/>
                <TextField source="email"/>
                <TextField source="phone"/>
                <ServicesField label="Szolgáltatások" source="services"/>
                <FunctionField label="Első kör" render={record => {
                    switch (record.roundOneResult) {
                        case 'accepted':
                            return <CheckIcon/>;
                        case 'rejected':
                            return <BlockIcon/>;
                        default:
                            return null;
                    }
                }}/>
                <FunctionField label="Második kör" render={record => {
                    switch (record.roundTwoResult) {
                        case 'accepted':
                            return <CheckIcon/>;
                        case 'rejected':
                            return <BlockIcon/>;
                        default:
                            return null;
                    }
                }}/>
                <TextField source="createdAt" label='Jelentkezés'/>
                <TextField label="Ország" source="billingAddressObj.country"/>
                <TextField label='Átlagos értékelés' source='averageRating'/>
                {permissions.indexOf('jury') > -1 && <ShowButton/>}
                {permissions.indexOf('admin') > -1 && <EditButton/>}
                <PhotosButton/>
                <FunctionField render={record =>
                    permissions.indexOf('jury') > -1 && record.roundTwoResult === null
                        ? <AcceptButton record={record}/>
                        : null}
                />
                <FunctionField render={record =>
                    permissions.indexOf('jury') > -1 && record.roundTwoResult === null
                        ? <RejectButton record={record}/>
                        : null}
                />
                {permissions.indexOf('admin') > -1 && <DeleteButton/>}
            </Datagrid>}
    </List>
);

const CreativeTitle = ({record}) => {
    return <span>Kreatív {record ? `"${record.name}"` : ''}</span>;
};

const CreativeEditActions = ({basePath, data}) => (
    <CardActions style={cardActionStyle}>
        <ReviewsButton basePath={basePath} record={data}/>
        <ShowButton basePath={basePath} record={data}/>
        <ListButton basePath={basePath}/>
        <RefreshButton/>
    </CardActions>
);

export const CreativeEdit = (props) => (
    <Edit title={<CreativeTitle/>} actions={<CreativeEditActions/>} {...props} >
        <SimpleForm redirect="list">
            <TextInput label="Neve" source="name"/>
            <TextInput source="email" type="email"/>
            <TextInput source="phone" type="number"/>
            <CheckboxGroupInput label="Szolgáltatások" source="services" choices={filteredServices}/>
            <TextInput label="Ország" source="billingAddressObj.country"/>
            <SelectArrayInput label="Városok" source="workingArea" />
            <EmbeddedArrayInput label="Portfolió URLek" source="portfolioUrl">
                <TextInput label="Portfolió URL" type="url"/>
            </EmbeddedArrayInput>
            <TextInput type="url" source="facebookUrl"/>
            <TextInput type="url" source="pinterestUrl"/>
            <TextInput type="url" source="instagramUrl"/>
            <TextInput type="url" source="linkedinUrl"/>
            <NumberInput label="Sikeresen zárt offline munkák száma" source="offlineWorks" />
            <LongTextInput label="Megjegyzés" source="notes"/>
            <TextInput label="Hol hallott rólunk?" source="heardAboutUs"/>
            <BooleanInput label="Hírlevél?" source="newsletter"/>
            <SelectInput source="billingLanguage" label="Számlázási nyelv" choices={languages}
            optionText={(choice) => `${choice.name}`}
            />
            <EmbeddedArrayInput label="Értékelések" source="reviews">
                <LongTextInput label="Értékelés"/>
            </EmbeddedArrayInput>
            <BooleanField label="Adatvédelmi nyilatkozatot elfogadta?" source="acceptsPrivacyPolicy"/>
        </SimpleForm>
    </Edit>
);

export const CreativeCreate = (props) => (
    <Create title="Kreatív létrehozás" {...props} >
        <SimpleForm redirect="list">
            <TextInput label="Név" source="name"/>
            <TextInput source="email" type="email"/>
            <CheckboxGroupInput label="Szolgáltatások" source="services" choices={services}/>
            <EmbeddedArrayInput label="Portfolió URLek" source="portfolioUrl">
                <TextInput label="Portfolió URL" type="url"/>
            </EmbeddedArrayInput>
            <TextInput type="url" source="facebookUrl"/>
            <TextInput type="url" source="pinterestUrl"/>
            <TextInput type="url" source="instagramUrl"/>
            <TextInput type="url" source="linkedinUrl"/>
            <NumberInput label="Sikeresen zárt offline munkák száma" source="offlineWorks" />
            <LongTextInput label="Megjegyzés" source="notes"/>
            <TextInput label="Hol hallott rólunk?" source="heardAboutUs"/>
            <BooleanInput label="Hírlevél?" source="newsletter"/>
        </SimpleForm>
    </Create>
);

const CreativeShowActions = ({basePath, data}) => (
    <CardActions style={cardActionStyle}>
        <ReviewsButton basePath={basePath} record={data}/>
        <WithPermission value={'admin'}>
            <EditButton basePath={basePath} record={data}/>
        </WithPermission>
        <ListButton basePath={basePath}/>
        <RefreshButton/>
    </CardActions>
);

export const CreativeShow = (props) => (
    <Show title="Kreatív" actions={<CreativeShowActions/>} {...props} >
        <SimpleShowLayout>
            <TextField label="Neve" source="name"/>
            <EmailField source="email"/>
            <TextField label="Phone" source="phone"/>
            <FunctionField label="Szolgáltatások" render={record => (
                <div style={styles.wrapper}>
                    {record.services.map(service => <Chip style={styles.chip}>{serviceMap[service]}</Chip>)}
                </div>
            )
            }
            />
            <TextField label="Ország" source="billingAddressObj.country"/>

            <FunctionField label="Városok" render={record => (
                record.workingArea && record.workingArea.length >0  &&
             <div style={styles.wrapper}>
                    {record.workingArea.map(city => <Chip style={styles.chip}>{city}</Chip>)}
                </div>
                )
            }
            />
            <EmbeddedArrayField label="Portfolió URL" source="portfolioUrl">
                <UrlFieldWithTarget target="_blank"/>
            </EmbeddedArrayField>
            <UrlFieldWithTarget source="facebookUrl" target="_blank"/>
            <UrlFieldWithTarget source="pinterestUrl" target="_blank"/>
            <UrlFieldWithTarget source="instagramUrl" target="_blank"/>
            <UrlFieldWithTarget source="linkedinUrl" target="_blank"/>
            <NumberField label="Sikeresen zárt offline munkák száma" source="offlineWorks" />
            <TextField label="Megjegyzés" source="notes"/>
            <TextField label="Hol hallott rólunk?" source="heardAboutUs"/>
            <BooleanField label="Hírlevél?" source="newsletter"/>
            <BooleanField label="Adatvédelmi nyilatkozatot elfogadta?" source="acceptsPrivacyPolicy"/>
            <TextField label="Számlázási név" source="billingName"/>
            <TextField label="Számlázási cím" source="billingAddress"/>
            <TextField label="Adószám" source="taxNumber"/>
            <TextField label="Bank" source="bank"/>
            <TextField label="Számlaszám" source="accountNumber"/>
            <TextField label="SWIFT/BIC" source="accountCode"/>
            <FunctionField
            label="Számlázási nyelv"
            source="billingLanguage"
            render={(record) => record.billingLanguage === 'hu' ? 'HU' : 'AUT'}
            />
            <FunctionField
                label="Teljesítési igazolások"
                render={
                    record => {
                        return record.tigs && record.tigs.length > 0
                            ? <div style={styles.wrapper}>
                                <Table selectable={false}>
                                    <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                                        <TableRow>
                                            <TableHeaderColumn>Dátum (elszámolt hónap)</TableHeaderColumn>
                                            <TableHeaderColumn>Teljesítési igazolás</TableHeaderColumn>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody displayRowCheckbox={false}>
                                        {
                                            record.tigs.map(
                                                tig => {
                                                    const startDate = new Date(Date.parse(tig.date))
                                                    return <TableRow>
                                                        <TableRowColumn>{startDate.getFullYear()} - {String(startDate.getMonth() + 1).padStart(2, '0')}</TableRowColumn>
                                                        <TableRowColumn><a href={tig.url} target="_blank" rel="noreferrer">Letöltés</a></TableRowColumn>
                                                    </TableRow>
                                                }
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                            : <div>Nincsenek teljesítési igazolások</div>
                    }
                }
            />
        </SimpleShowLayout>
    </Show>
);
