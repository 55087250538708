import {
    AUTH_LOGIN,
    AUTH_LOGOUT,
    AUTH_ERROR,
    AUTH_CHECK,
    AUTH_GET_PERMISSIONS,
} from 'admin-on-rest';
import jwtDecode from 'jwt-decode';


export const AUTH_GET_USER_ID = 'AUTH_GET_USER_ID';

export default (apiUrl) => {
    return (type, params) => {
        if (type === AUTH_LOGIN) {
            const { username, password } = params;
            const request = new Request(`${apiUrl}/authenticate`, {
                method: 'POST',
                body: JSON.stringify({ email: username, password }),
                headers: new Headers({ 'Content-Type': 'application/json' }),
            })
            return fetch(request)
                .then(response => {
                    if (response.status < 200 || response.status >= 300) {
                        throw new Error(response.body.message);
                    }
                    return response.json();
                })
                .then(({ token }) => {
                    const decoded = jwtDecode(token);
                    localStorage.setItem('token', token);
                    localStorage.setItem('roles', decoded.roles);
                    localStorage.setItem('userId', decoded.id);
                });
        }
        if (type === AUTH_LOGOUT) {
            localStorage.removeItem('token');
            localStorage.removeItem('roles');
            localStorage.removeItem('userId');
            return Promise.resolve();
        }
        if (type === AUTH_ERROR) {
            if (params instanceof Error && 'status' in params && params.status === 401) {
                localStorage.removeItem('token');
                localStorage.removeItem('roles');
                localStorage.removeItem('userId');
            }
            return Promise.resolve();
        }
        if (type === AUTH_CHECK) {
            return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
        }
        if (type === AUTH_GET_PERMISSIONS) {
            const roles = localStorage.getItem('roles') !== null ? localStorage.getItem('roles') : '';
            return Promise.resolve(roles.split(','));
        }
        if (type === AUTH_GET_USER_ID) {
            return Promise.resolve(localStorage.getItem('userId'));
        }
        return Promise.reject('Unkown method');
    };
}
